import React, { useState, useEffect } from 'react';
import { InputLabel, Button, FormControl, Dialog, DialogContent, DialogTitle, IconButton, Stack, TextField, CircularProgress } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AddTeamModal = (props) => {
  const [isSubmitting, setIsSubmitting] = useState(false); 
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [formData, setFormData] = useState({ "Team name": "" });
  const [requiredFields, setRequiredFields] = useState([]);
  const [teamNames, setTeamNames] = useState([]); // To store existing team names
  const [numTeams, setNumTeams] = useState(0);
  const maxTeams = props.eventSettings.tier === "Tier 1" ? 50 : Infinity;

  useEffect(() => {
    fetchConfigAndData(); 
    fetchTeamNames(); // Fetch the list of existing team names
  }, []);

  const fetchConfigAndData = async () => {
    try {
      const defaultFields = props.eventSettings.defaultTeamFields || [];
      const customFields = props.eventSettings.customTeamFields || [];
      const allFields = [...defaultFields, ...customFields];

      const initialFormData = { "Team name": "" }; // Always include "Team name"
      const requiredFieldsList = ["Team name"]; // Always require "Team name"

      allFields.forEach(field => {
        initialFormData[field.field] = ''; 
        if (field.required) {
          requiredFieldsList.push(field.field);
        }
      });

      setFormData(initialFormData);
      setRequiredFields(requiredFieldsList);
    } catch (error) {
      console.log('Error loading initial data:', error);
    }
  };

  const fetchTeamNames = async () => {
    try {
      const apiUrl = process.env.REACT_APP_NODE_ENV === 'production'
        ? process.env.REACT_APP_SERVER_URL_PRODUCTION
        : process.env.REACT_APP_SERVER_URL_STAGING;

      const response = await fetch(`${apiUrl}/api/admin_get_event_team_names`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username: props.username, eventId: props.eventId })
      });

      if (response.ok) {
        const data = await response.json();
        setTeamNames(data.teamNames || []);
        setNumTeams(data.teamNames.length);
      } else {
        toast.error('Error fetching team names.');
      }
    } catch (error) {
      console.error('Error fetching team names:', error);
      toast.error('Error fetching team names.');
    }
  };

  const delayRefresh = () => {
    setTimeout(() => {
      window.location.reload();
    }, 2000);
  };

  const handleClose = () => {
    setFormData({ "Team name": "" });
    setIsSubmitted(false);
    setIsSubmitting(false);
    props.close();
  };

  const validateUserInput = () => {
    let isValid = true;

    // Ensure maxTeams is not exceeded
    if (numTeams >= maxTeams) {
      toast.error('Team limit has been reached.');
      return false;
    }

    requiredFields.forEach(field => {
      const fieldType = props.eventSettings.defaultTeamFields.find(f => f.field === field)?.type 
                      || props.eventSettings.customTeamFields.find(f => f.field === field)?.type;

      if (!formData[field]) {
        toast.error(`${field} cannot be empty.`);
        isValid = false;
      }

      if (field === 'Team name' && teamNames.includes(formData[field])) {
        toast.error('Team name already exists.');
        isValid = false;
      }

      if (fieldType === 'Email' && !validateEmail(formData[field])) {
        toast.error(`Invalid email format for ${field}.`);
        isValid = false;
      } else if (fieldType === 'Phone' && !validatePhone(formData[field])) {
        toast.error(`Invalid phone number for ${field}.`);
        isValid = false;
      }
    });

    return isValid;
  };

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const validatePhone = (phone) => {
    const regex = /^\d{10}$/;
    return regex.test(phone);
  };

  const handleFormSubmission = async () => {
    if (validateUserInput()) {
      setIsSubmitting(true);

      try {
        const apiUrl = process.env.REACT_APP_NODE_ENV === 'production'
          ? process.env.REACT_APP_SERVER_URL_PRODUCTION
          : process.env.REACT_APP_SERVER_URL_STAGING;

        const response = await fetch(`${apiUrl}/api/admin_create_event_teams`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            username: props.username,
            eventId: props.eventId,
            team: formData,
          }),
        });

        if (response.ok) {
          toast.success('Team registered successfully!');
          setIsSubmitted(true);
          delayRefresh();
        } else {
          toast.error('Error registering team.');
          setIsSubmitting(false);
        }
      } catch (error) {
        console.error('Error registering team:', error);
        toast.error('Error registering team.');
        setIsSubmitting(false);
      }
    }
  };

  const handleInputChange = (field, value) => {
    setFormData(prevState => ({
      ...prevState,
      [field]: value,
    }));
  };

  const renderField = (field, type, required) => {
    switch (type) {
      case 'Text':
        return (
          <TextField
            label={field}
            value={formData[field]}
            onChange={(e) => handleInputChange(field, e.target.value)}
            required={required}
            fullWidth
          />
        );
      case 'Number':
        return (
          <TextField
            label={field}
            type="number"
            value={formData[field]}
            onChange={(e) => handleInputChange(field, e.target.value)}
            required={required}
            fullWidth
          />
        );
      case 'Email':
        return (
          <TextField
            label={field}
            type="email"
            value={formData[field]}
            onChange={(e) => handleInputChange(field, e.target.value)}
            required={required}
            helperText="Enter a valid email address"
            fullWidth
          />
        );
      case 'Phone':
        return (
          <TextField
            label={field}
            value={formData[field]}
            onChange={(e) => handleInputChange(field, e.target.value)}
            required={required}
            helperText="Enter a 10-digit phone number"
            fullWidth
          />
        );
      default:
        return null;
    }
  };

  return (
    <Dialog open={props.status} onClose={handleClose} fullWidth maxWidth="xl">
      <form onSubmit={(e) => { e.preventDefault(); handleFormSubmission(); }}>
        <DialogTitle>
          Register a New Team
          <IconButton onClick={handleClose} style={{ float: 'right' }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Stack spacing={2} margin={2}>

            {/* Dynamically generate form fields */}
            {props.eventSettings.defaultTeamFields.concat(props.eventSettings.customTeamFields).map((field, index) => (
              <FormControl key={index} fullWidth>
                {renderField(field.field, field.type, field.required)}
              </FormControl>
            ))}

            <InputLabel>* indicates a required field</InputLabel>

            {/* Submit Button */}
            {!isSubmitted ? (
              <Button
                color="primary"
                variant="contained"
                onClick={handleFormSubmission}
                disabled={isSubmitting}
                startIcon={isSubmitting ? <CircularProgress size={20} /> : null}
              >
                {isSubmitting ? "Submitting..." : "Register Team"}
              </Button>
            ) : (
              <h3>Submitted!</h3>
            )}
          </Stack>
        </DialogContent>
      </form>
    </Dialog>
  );
};

export default AddTeamModal;

