import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import "react-responsive-carousel/lib/styles/carousel.min.css";

import AnimatedPage from './AnimatedPage';
import HomeCountdownTimer from '../components/timers/HomeCountdownTimer';
import Footer from '../components/footer/Footer';
import './HomePage.css';

function HomePage() {
  const { username, eventId } = useParams(); // Get username and eventId from the URL
  const [eventSettings, setEventSettings] = useState(null); // Store event settings
  const [logo, setLogo] = useState();    
  const [numTeams, setNumTeams] = useState(0);    
  const [numCatches, setNumCatches] = useState(0);   

  useEffect(() => {
    const apiUrl = process.env.REACT_APP_NODE_ENV === 'production'
      ? process.env.REACT_APP_SERVER_URL_PRODUCTION
      : process.env.REACT_APP_SERVER_URL_STAGING;

    // Fetch event settings
    const fetchEventSettings = async () => {
      try {
        const response = await fetch(`${apiUrl}/api/load_event_profile`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ username, eventId }),
        });
        const data = await response.json();
        if (data.success) {
          setEventSettings(data.settings); // Store settings in state
          console.log('Event settings:', data.settings);
        } else {
          console.error('Error fetching event settings:', data.message);
        }
      } catch (error) {
        console.error('Error fetching event settings:', error);
      }
    };

    // Fetch total number of teams
    const fetchTotalTeams = async () => {
      try {
        const response = await fetch(`${apiUrl}/api/get_total_num_teams`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ username, eventId }),
        });
        const data = await response.json();
        if (data.success) {
          setNumTeams(data.total); // Set total number of teams
        } else {
          console.error('Error fetching total teams:', data.message);
        }
      } catch (error) {
        console.error('Error fetching total teams:', error);
      }
    };

    // Fetch total number of catches
    const fetchTotalCatches = async () => {
      try {
        const response = await fetch(`${apiUrl}/api/get_total_num_catches`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ username, eventId }),
        });
        const data = await response.json();
        if (data.success) {
          setNumCatches(data.total); // Set total number of catches
        } else {
          console.error('Error fetching total catches:', data.message);
        }
      } catch (error) {
        console.error('Error fetching total catches:', error);
      }
    };

    fetchEventSettings();
    fetchTotalTeams();
    fetchTotalCatches();
  }, [username, eventId]);

  useEffect(() => {
    // Handle dynamic logo resizing based on window width
    const updateLogo = () => {
      if (eventSettings && eventSettings.eventLogoUrl) {
        if (window.innerWidth <= 750) {
          setLogo(eventSettings.eventLogoUrl); // Mobile: fixed width
        } else {
          setLogo(eventSettings.eventLogoUrl); // Desktop/Tablet: percentage based
        }
      }
    };

    window.addEventListener('resize', updateLogo);
    updateLogo(); // Call once initially

    return () => {
      window.removeEventListener('resize', updateLogo);
    };
  }, [eventSettings]);

  // Helper function to format epoch time to a readable date
  const formatDate = (epochTime) => {
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    const date = new Date(Number(epochTime));
    return date.toLocaleDateString('en-US', options);
  };

  if (!eventSettings) {
    return <div>Loading...</div>; 
  }

  return (
    <AnimatedPage>
      <main>

        <section style={{ backgroundColor: eventSettings.backgroundColor }} className="section-hero">
          <img
            src={logo}
            alt="Tournament Logo"
            style={{ width: '60%' }} // Dynamically resizing logo
          />
          <br />
          <br />
          <h1 style={{ color: eventSettings.headlineTextColor }}>{eventSettings.eventTitle}</h1>
          {/* Dynamically formatted start and end date */}
          <h2 style={{ color: eventSettings.headlineTextColor }}>
            {`${formatDate(eventSettings.eventStartDateTimeGMT)} - ${formatDate(eventSettings.eventEndDateTimeGMT)}`}
          </h2>
          <div className="tournamentColElement">
            <HomeCountdownTimer className="countdown-timer-element" targetDate={parseInt(eventSettings.eventStartDateTimeGMT, 10)} />
          </div>
          <br/>
        </section>

        <section style={{backgroundColor: "white"}} className="section-hero2">
          <br/>
          <p key="homepageSubheadline" style={{ color: "black" }}>{eventSettings.homepageSubheadline}</p>
          <br/>
          <p key="numTeams" style={{ color: "black" }}>Total Teams: {numTeams}</p> {/* Display number of teams */}
          <p key="numCatches" style={{ color: "black" }}>Total Catches: {numCatches}</p> {/* Display number of catches */}
        </section>

        <Footer />
      </main>
    </AnimatedPage>
  );
}

export default HomePage;

