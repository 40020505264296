import React, { useState, useEffect } from 'react';
import { InputLabel, Button, Dialog, DialogContent, DialogTitle, IconButton, Stack, CircularProgress } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';

const DeleteCatchModal = (props) => {
  const [deleteInfo, setDeleteInfo] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);  // New state to track submission
  const [isSubmitted, setIsSubmitted] = useState(false);    // New state to track successful submission

  useEffect(() => {
    setDeleteInfo(props.deleteInfo);
  }, [props.deleteInfo]);

  const handleClose = () => {
    setIsSubmitting(false);
    setIsSubmitted(false);
    props.close();
  };

  const delayRefresh = () => {
    setTimeout(() => {
      window.location.reload();
    }, 2000);
  };

  const handleDelete = async () => {
    setIsSubmitting(true); 

    try {
      let apiUrl = process.env.REACT_APP_NODE_ENV === "staging"
        ? process.env.REACT_APP_SERVER_URL_STAGING
        : process.env.REACT_APP_SERVER_URL_PRODUCTION;

      const response = await fetch(`${apiUrl}/api/admin_delete_event_catches`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          username: props.username,
          eventId: props.eventId,
          catchId: props.deleteInfo.catchId,
        })
      });

      if (response.ok) {
        toast.success('Catch deleted successfully.');
        setIsSubmitted(true);
        delayRefresh();
      } else {
        toast.error('Error deleting catch.');
        setIsSubmitting(false);
      }
    } catch (error) {
      console.error('Error deleting catch:', error);
      toast.error('Error deleting catch.');
      setIsSubmitting(false);
    }
  };

  const formatLocalDateTime = (utcDateTime) => {
    const dateObj = new Date(utcDateTime);
    const localDate = dateObj.toLocaleDateString(); // Convert to local date string
    const localTime = dateObj.toLocaleTimeString(); // Convert to local time string
    return { localDate, localTime };
  };

  return (
    <div>
      {deleteInfo &&
        <Dialog open={props.status} onClose={handleClose} fullWidth maxWidth="sm">
          <form action="/" method="POST" onSubmit={(e) => { e.preventDefault(); handleClose(); }}>
            <DialogTitle>
              Delete Catch
              <IconButton onClick={handleClose} style={{ float: 'right' }}><CloseIcon color="primary"></CloseIcon></IconButton>
            </DialogTitle>
            <DialogContent>
              <Stack spacing={2} margin={2}>
                <div>
                  <InputLabel id="catch-id-label"><strong>Catch ID:</strong>  {deleteInfo["catchId"]}</InputLabel>
                  <InputLabel id="species-label"><strong>Species:</strong>  {deleteInfo["species"]}</InputLabel>
                  <InputLabel id="species-type-label"><strong>Type:</strong>  {deleteInfo["speciesType"]}</InputLabel>
                  <InputLabel id="name-label"><strong>Team:</strong>  {deleteInfo["teamName"]}</InputLabel>
                  <InputLabel id="length-label"><strong>Length:</strong>  {deleteInfo["length"]} in</InputLabel>
                  <InputLabel id="girth-label"><strong>Girth:</strong>  {deleteInfo["girth"]} in</InputLabel>
                  <InputLabel id="weight-label"><strong>Weight:</strong>  {deleteInfo["weight"]} lbs</InputLabel>
                  <InputLabel id="points-label"><strong>Points:</strong>  {deleteInfo["points"]}</InputLabel>
                  <InputLabel id="date-label"><strong>Date Entered:</strong>  {formatLocalDateTime(deleteInfo["dateTime"]).localDate}</InputLabel>
                  <InputLabel id="time-label"><strong>Time Entered:</strong>  {formatLocalDateTime(deleteInfo["dateTime"]).localTime}</InputLabel>
                </div>

                <InputLabel style={{ fontWeight: 'bold', color: 'red', wordWrap: 'break-word' }}>Are you sure?</InputLabel>
                
                {!isSubmitted ? (
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={handleDelete}
                    disabled={isSubmitting || isSubmitted}  // Disable during submission or after it's submitted
                    startIcon={isSubmitting ? <CircularProgress size={20} /> : null}  // Show spinner while submitting
                  >
                    {isSubmitting ? "Deleting..." : "Yes, Delete This Catch"}
                  </Button>
                ) : (
                  <h3>Submitted!</h3>
                )}
              </Stack>
          </DialogContent>
        </form>
      </Dialog>
    }
  </div>
  );
};

export default DeleteCatchModal;

