import React, { useEffect, useState } from 'react';

function ErrorPage() {
  return (
    <>
      <main>
        <br/>
        <br/>
        <h2>An error occurred!</h2>
        <p>Could not find this page :(</p>
        <p>Please refresh your browser.</p>
      </main>
    </>
  );
}

export default ErrorPage;

