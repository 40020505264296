import React, { useState } from 'react';
import { Checkbox, FormControlLabel, Dialog, DialogContent, DialogTitle, IconButton, Tabs, Tab, Box, Button, Stack, TextField, MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { SketchPicker } from 'react-color';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import { useTheme, useMediaQuery } from '@mui/material';
import { toast } from 'react-toastify';
import Resizer from 'react-image-file-resizer'; 
import 'react-toastify/dist/ReactToastify.css';

// Function to render the content for each tab
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

// Helper function for accessibility props
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const CreateEventModal = (props) => {

  const loggedInUserName = window.localStorage.getItem('username');
  
  const [activeTab, setActiveTab] = useState(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Check if screen size is mobile

  // STATE - General
  const [eventTitle, setEventTitle] = useState('');
  const [tier, setTier] = useState('Tier 1');
  const [rulesLink, setRulesLink] = useState();
  const [websiteLink, setWebsiteLink] = useState();
  const [backgroundColor, setBackgroundColor] = useState("gray");
  const [headlineTextColor, setHeadlineTextColor] = useState("black");
  const [subheadlineTextColor, setSubheadlineTextColor] = useState("black");
  const [backgroundColorDialogOpen, setBackgroundColorDialogOpen] = useState(false);
  const [headlineTextColorDialogOpen, setHeadlineTextColorDialogOpen] = useState(false);
  const [subheadlineTextColorDialogOpen, setSubheadlineTextColorDialogOpen] = useState(false);
  const [homepageSubheadline, setHomepageSubheadline] = useState();
  const [eventLogo, setEventLogo] = useState();
  const [eventLogoName, setEventLogoName] = useState(''); // New state to track the original file name
  const [eventStartDateTime, setEventStartDateTime] = useState(null);
  const [eventEndDateTime, setEventEndDateTime] = useState(null);
  const [missingFields, setMissingFields] = useState([]);  // State to track missing fields for validation

  const [isSubmitting, setIsSubmitting] = useState(false);  // Track if form is being submitted
  const [isSubmitted, setIsSubmitted] = useState(false);    // Track if form was successfully submitted

  // STATE - Teams
  const [defaultTeamFields, setDefaultTeamFields] = useState([
    { field: 'Team name', type: 'Text', required: true },
    { field: 'Team email', type: 'Email', required: true },
    { field: 'Team phone', type: 'Phone', required: true },
  ]);
  const [customTeamFields, setCustomTeamFields] = useState([]);

  // STATE - Catches
  const [catchRows, setCatchRows] = useState([{ species: '', scoring: 'Catch & Release (Tiebreaker: Earliest Time)', points: 0 }]);

  // FETCH
  const handleCreateEventAsAdmin = async () => {
    // Validate form before submission
    const validationErrors = validateForm();
    if (validationErrors.length > 0) {
      setMissingFields(validationErrors);
      toast.warning("Please fill in data for the missing fields.");
      return;
    }
  
    try {
      setIsSubmitting(true);
  
      // Create FormData for multipart upload
      const formData = new FormData();
      formData.append('username', loggedInUserName);  // Pass username as a stringified object
      formData.append('eventTitle', eventTitle);
      formData.append('tier', tier);
      formData.append('rulesLink', rulesLink);
      formData.append('websiteLink', websiteLink);
      formData.append('backgroundColor', backgroundColor);
      formData.append('headlineTextColor', headlineTextColor);
      formData.append('subheadlineTextColor', subheadlineTextColor);
      formData.append('eventStartDateTimeGMT', eventStartDateTime ? eventStartDateTime.valueOf() : ''); // Convert to epoch time
      formData.append('eventEndDateTimeGMT', eventEndDateTime ? eventEndDateTime.valueOf() : ''); // Convert to epoch time
      formData.append('homepageSubheadline', homepageSubheadline || '');
      formData.append('catchRows', JSON.stringify(catchRows));
      formData.append('teamFields', JSON.stringify({
        default: defaultTeamFields,
        custom: customTeamFields,
      }));
  
      // Attach the resized image Blob if it exists
      if (eventLogo) {
        formData.append('eventLogo', eventLogo, eventLogoName);
      }
  
      const apiUrl = process.env.REACT_APP_NODE_ENV === "staging"
        ? process.env.REACT_APP_SERVER_URL_STAGING
        : process.env.REACT_APP_SERVER_URL_PRODUCTION;
  
      // Send the request to the server using FormData
      const response = await fetch(`${apiUrl}/api/create_event_in_firebase`, {
        method: 'POST',
        body: formData,  // Send FormData as the request body
      });
  
      if (response.ok) {
        toast.success("Event created successfully.");
        delayRefresh();
        // handleClose();
      } else {
        toast.error("Error creating event.");
      }
    } catch (error) {
      console.error("Error creating event:", error);
      toast.error("Error creating event.");
    } finally {
      setIsSubmitting(false);
    }
  };
  

  const validateForm = () => {
    const errors = [];

    // General Tab
    if (!eventTitle) errors.push('Event Title');
    if (!tier) errors.push('Tier');
    if (!backgroundColor) errors.push('Background Color');
    if (!headlineTextColor) errors.push('Headline Text Color');
    if (!subheadlineTextColor) errors.push('Subheadline Text Color');
    if (!eventStartDateTime) errors.push('Event Start Date');
    if (!eventEndDateTime) errors.push('Event End Date');
    if (eventStartDateTime && eventEndDateTime && eventEndDateTime.isBefore(eventStartDateTime)) {
      errors.push('Event End Date must be greater than or equal to Event Start Date');
    }

    // Team Fields
    defaultTeamFields.forEach((field, index) => {
      if (field.required && !field.field) {
        errors.push(`Default Team Field ${index + 1} is missing a name.`);
      }
    });

    customTeamFields.forEach((field, index) => {
      if (field.required && !field.field) {
        errors.push(`Custom Team Field ${index + 1} is missing a name.`);
      }
    });

    // Catch Fields
    catchRows.forEach((catchRow, index) => {
      if (!catchRow.species) errors.push(`Name for species #${index + 1}`);
      if (catchRow.scoring === 'Catch & Release (Tiebreaker: Earliest Time)' && catchRow.points === 0) {
        errors.push(`Point amount for species #${index + 1}`);
      }
    });

    return errors;
  };
  
  // HELPERS
  const delayRefresh = () => {
    setTimeout(() => {
      console.log('Delaying page refresh...');
      window.location.reload();
    }, 2000);
  };

  const handleOpenDialog = (setDialogOpen) => () => setDialogOpen(true);
  const handleCloseDialog = (setDialogOpen) => () => setDialogOpen(false);

  const handleLogoUpload = (e) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      const fileType = file.type.split('/')[1]; // Extract file type (e.g., 'jpeg' from 'image/jpeg')
      const fileName = file.name; // Get the original file name

      // Save the file name and extension separately
      setEventLogoName(fileName);

      // Resize the image using react-image-file-resizer
      Resizer.imageFileResizer(
        file,   // Original file
        300,    // Max width
        300,    // Max height
        fileType.toUpperCase(), // Use the original file type (e.g., 'JPEG', 'PNG')
        80,     // Quality percentage (0-100)
        0,      // Rotation
        (uri) => {
          // Convert resized base64 image to Blob and set it in state
          fetch(uri)
            .then(res => res.blob())
            .then(blob => {
              // Create a new Blob object with the original file type
              const resizedBlob = new Blob([blob], { type: file.type });
              setEventLogo(resizedBlob);
            })
            .catch(error => {
              console.error('Error resizing image:', error);
              toast.error('Error resizing image.');
            });
        },
        'base64' // Output format
      );
    }
  };

  const handleRemoveLogo = () => {
    setEventLogo(null);
    document.getElementById(`event-logo-upload-button`).value = '';
  };

  const updateDefaultTeamField = (index, key, value) => {
    const updatedFields = [...defaultTeamFields];
    updatedFields[index][key] = value;
    setDefaultTeamFields(updatedFields);
  };

  const updateCustomTeamField = (index, key, value) => {
    const updatedFields = [...customTeamFields];
    updatedFields[index][key] = value;
    setCustomTeamFields(updatedFields);
  };

  const addCustomTeamField = () => {
    setCustomTeamFields([...customTeamFields, { field: '', type: 'Text', required: false }]);
  };

  const handleRemoveDefaultTeamField = (index) => {
    const updatedFields = [...defaultTeamFields];
    updatedFields.splice(index, 1);
    setDefaultTeamFields(updatedFields);
  };

  const handleRemoveCustomTeamField = (index) => {
    const updatedFields = [...customTeamFields];
    updatedFields.splice(index, 1);
    setCustomTeamFields(updatedFields);
  };

  const handleAddCatchRow = () => {
    setCatchRows([...catchRows, { species: '', scoring: 'Catch & Release (Tiebreaker: Earliest Time)', points: 0 }]);
  };

  const handleCatchAttributeChange = (e, index, attribute) => {
    const newCatchRows = [...catchRows];
    newCatchRows[index][attribute] = e.target.value;
    setCatchRows(newCatchRows);
  };

  const handleRemoveCatchRow = (index) => {
    const newCatchRows = catchRows.filter((_, i) => i !== index);
    setCatchRows(newCatchRows.length > 0 ? newCatchRows : [{ species: '', scoring: 'Catch & Release (Tiebreaker: Earliest Time)', points: 0 }]);
  };

  const handleClose = () => {
    setActiveTab(0);
    setEventTitle('');
    setTier('');
    setRulesLink();
    setWebsiteLink();
    setBackgroundColor('gray');
    setHeadlineTextColor('black');
    setSubheadlineTextColor('black');
    setEventStartDateTime(null);
    setEventEndDateTime(null);
    setHomepageSubheadline('');
    setEventLogo(null);
    setDefaultTeamFields([
      { field: 'Team name', type: 'Text', required: true },
      { field: 'Team email', type: 'Email', required: true },
      { field: 'Team phone', type: 'Phone', required: true },
    ]);
    setCustomTeamFields([]);
    setCatchRows([{ species: '', scoring: 'Catch & Release (Tiebreaker: Earliest Time)', points: 0 }]);
    setIsSubmitting(false);
    setIsSubmitted(false);
    props.close();
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleNext = () => {
    if (activeTab < 5) setActiveTab((prev) => prev + 1);
  };

  const handleBack = () => {
    if (activeTab > 0) setActiveTab((prev) => prev - 1);
  };

  return (
    <Dialog
      open={props.status}
      onClose={handleClose}
      fullWidth
      maxWidth="lg"
      disableEscapeKeyDown={false}
    >
      <DialogTitle>
        Create New Event
        <IconButton onClick={handleClose} style={{ float: 'right' }}>
          <CloseIcon color="primary" />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Tabs value={activeTab} onChange={handleTabChange} aria-label="event creation tabs" variant="fullWidth">
          <Tab label="General" {...a11yProps(0)} />
          <Tab label="Teams" {...a11yProps(1)} />
          <Tab label="Catches" {...a11yProps(2)} />
          <Tab label="Registration" {...a11yProps(3)} />
          <Tab label="Pots" {...a11yProps(4)} />
          <Tab label="Payment" {...a11yProps(5)} />
        </Tabs>

        {/* General Tab */}
        <TabPanel value={activeTab} index={0}>
          <h3>General Settings</h3>
          <p>Customize how you want your event website to appear.</p>
          <br/>
          <Stack spacing={2}>
            <TextField
              label="Event Title (e.g. Legends Fishing Tournament 2024)"
              value={eventTitle}
              onChange={(e) => setEventTitle(e.target.value)}
              fullWidth
              required
            />    
            <FormControl fullWidth>
              <InputLabel>Tier</InputLabel>
              <Select
                label="Tier"
                value={tier}
                onChange={(e) => setTier(e.target.value)}
                fullWidth
                required
              >
                <MenuItem value="Tier 1">Tier 1</MenuItem>
                <MenuItem value="Tier 2">Tier 2</MenuItem>
                <MenuItem value="Tier 3">Tier 3</MenuItem>
                <MenuItem value="Tier 4">Tier 4</MenuItem>
              </Select>
            </FormControl>

            {/* External Links */}
            <TextField
              label="Link to your additional event website (if you have one you want to include)"
              value={websiteLink}
              onChange={(e) => setWebsiteLink(e.target.value)}
              fullWidth
            />    
            <TextField
              label="Link to tournament rules"
              value={rulesLink}
              onChange={(e) => setRulesLink(e.target.value)}
              fullWidth
            />    

            {/* Background Color Picker */}
            <Box>
              <InputLabel>Background Color</InputLabel>
              <Button
                variant="outlined"
                onClick={handleOpenDialog(setBackgroundColorDialogOpen)}
                sx={{
                  backgroundColor: backgroundColor,
                  color: backgroundColor === '#ffffff' ? 'black' : 'white',
                  textTransform: 'none',
                }}
                required
              >
                {backgroundColor}
              </Button>
              <Dialog open={backgroundColorDialogOpen} onClose={handleCloseDialog(setBackgroundColorDialogOpen)}>
                <DialogTitle>
                  Select Background Color
                  <IconButton onClick={handleCloseDialog(setBackgroundColorDialogOpen)} style={{ float: 'right' }}>
                    <CloseIcon />
                  </IconButton>
                </DialogTitle>
                <DialogContent>
                  <SketchPicker
                    color={backgroundColor}
                    onChangeComplete={(color) => setBackgroundColor(color.hex)}
                  />
                </DialogContent>
              </Dialog>
            </Box>

            {/* Headline Text Color Picker */}
            <Box>
              <InputLabel>Headline Text Color</InputLabel>
              <Button
                variant="outlined"
                onClick={handleOpenDialog(setHeadlineTextColorDialogOpen)}
                sx={{
                  backgroundColor: headlineTextColor,
                  color: headlineTextColor === '#ffffff' ? 'black' : 'white',
                  textTransform: 'none',
                }}
              >
                {headlineTextColor}
              </Button>
              <Dialog open={headlineTextColorDialogOpen} onClose={handleCloseDialog(setHeadlineTextColorDialogOpen)}>
                <DialogTitle>
                  Select Headline Text Color
                  <IconButton onClick={handleCloseDialog(setHeadlineTextColorDialogOpen)} style={{ float: 'right' }}>
                    <CloseIcon />
                  </IconButton>
                </DialogTitle>
                <DialogContent>
                  <SketchPicker
                    color={headlineTextColor}
                    onChangeComplete={(color) => setHeadlineTextColor(color.hex)}
                  />
                </DialogContent>
              </Dialog>
            </Box>

            {/* Subheadline Text Color Picker */}
            <Box>
              <InputLabel>Subheadline Text Color</InputLabel>
              <Button
                variant="outlined"
                onClick={handleOpenDialog(setSubheadlineTextColorDialogOpen)}
                sx={{
                  backgroundColor: subheadlineTextColor,
                  color: subheadlineTextColor === '#ffffff' ? 'black' : 'white',
                  textTransform: 'none',
                }}
              >
                {subheadlineTextColor}
              </Button>
              <Dialog open={subheadlineTextColorDialogOpen} onClose={handleCloseDialog(setSubheadlineTextColorDialogOpen)}>
                <DialogTitle>
                  Select Subheadline Text Color
                  <IconButton onClick={handleCloseDialog(setSubheadlineTextColorDialogOpen)} style={{ float: 'right' }}>
                    <CloseIcon />
                  </IconButton>
                </DialogTitle>
                <DialogContent>
                  <SketchPicker
                    color={subheadlineTextColor}
                    onChangeComplete={(color) => setSubheadlineTextColor(color.hex)}
                  />
                </DialogContent>
              </Dialog>
            </Box>

            {/* Date Pickers for Event Start and End Dates */}
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Event Start Date"
                value={eventStartDateTime}
                onChange={(date) => setEventStartDateTime(date)}
                disablePast
                fullWidth
                required
              />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Event End Date"
                value={eventEndDateTime}
                onChange={(date) => setEventEndDateTime(date)}
                disablePast
                fullWidth
                required
              />
            </LocalizationProvider>

            {/* Text Field for Homepage Subheadline */}
            <TextField
              label="Homepage Message(s)"
              value={homepageSubheadline}
              onChange={(e) => setHomepageSubheadline(e.target.value)}
              multiline
              rows={4}
              fullWidth
            />

            {/* File Input for Event Logo */}
            <Box>
              <InputLabel>Event Logo</InputLabel>
              <input type="file" onChange={handleLogoUpload} id="event-logo-upload-button" />
              <br/>
              <br/>
              {eventLogo && (
                <Box display="flex" alignItems="center">
                  <img
                    src={URL.createObjectURL(eventLogo)}  // Preview resized image
                    alt="Event Logo"
                    style={{ width: '100px', height: '100px', objectFit: 'cover', marginRight: '10px' }}
                  />
                  <IconButton size="small" onClick={() => setEventLogo(null)}>
                    <CloseIcon fontSize="small" onClick={() => handleRemoveLogo() }/>
                  </IconButton>
                </Box>
              )}
            </Box>

          </Stack>
        </TabPanel>

        {/* Teams Tab */}
        <TabPanel value={activeTab} index={1}>
          <h3>Team Settings</h3>
          <p>Define what information you want from each team that registers.</p>
          <br/>
          { tier === "Tier 1" ? (
            <p><strong>Max number of teams allowed ({tier}):</strong> 50</p>
          ):(
            <p><strong>Max number of teams allowed ({tier}):</strong> Unlimited</p>
          )}
          <br/>

          {/* Default Rows */}
          {defaultTeamFields.map((field, index) => (
            <Stack
              direction={isMobile ? "column" : "row"}
              spacing={2}
              sx={{ mt: 2, borderBottom: isMobile ? '1px solid #ddd' : 'none', pb: isMobile ? 2 : 0 }}
              key={`default-${index}`}
            >
              <TextField
                label="Field Name"
                value={field.field}
                onChange={(e) => updateDefaultTeamField(index, 'field', e.target.value)}
                fullWidth
              />
              <FormControl fullWidth>
                <InputLabel>Data type</InputLabel>
                <Select
                  label="Data type"
                  value={field.type}
                  onChange={(e) => updateDefaultTeamField(index, 'type', e.target.value)}
                >
                  <MenuItem value="Text">Text</MenuItem>
                  <MenuItem value="Number">Number</MenuItem>
                  <MenuItem value="Email">Email</MenuItem>
                  <MenuItem value="Phone">Phone</MenuItem>
                </Select>
              </FormControl>
              <FormControlLabel
                control={<Checkbox checked={field.required} onChange={(e) => updateDefaultTeamField(index, 'required', e.target.checked)} />}
                label="Required?"
              />
              {isMobile ? (
                <Button
                  onClick={() => handleRemoveDefaultTeamField(index)}
                  variant="outlined"
                  color="error"
                  size="small"
                  sx={{ alignSelf: 'flex-start' }}  // Align button to top when in column mode
                >
                  Delete Field
                </Button>
              ) : (
                <IconButton onClick={() => handleRemoveDefaultTeamField(index)} size="small">
                  <CloseIcon />
                </IconButton>
              )}
            </Stack>
          ))}

          {/* Custom Rows */}
          {customTeamFields.map((field, index) => (
            <Stack
              direction={isMobile ? "column" : "row"}
              spacing={2}
              sx={{ mt: 2, borderBottom: isMobile ? '1px solid #ddd' : 'none', pb: isMobile ? 2 : 0 }}
              key={`custom-${index}`}
            >
              <TextField
                label="Field Name"
                value={field.field}
                onChange={(e) => updateCustomTeamField(index, 'field', e.target.value)}
                fullWidth
              />
              <FormControl fullWidth>
                <InputLabel>Data type</InputLabel>
                <Select
                  label="Data type"
                  value={field.type}
                  onChange={(e) => updateCustomTeamField(index, 'type', e.target.value)}
                >
                  <MenuItem value="Text">Text</MenuItem>
                  <MenuItem value="Number">Number</MenuItem>
                  <MenuItem value="Email">Email</MenuItem>
                  <MenuItem value="Phone">Phone</MenuItem>
                </Select>
              </FormControl>
              <FormControlLabel
                control={<Checkbox checked={field.required} onChange={(e) => updateCustomTeamField(index, 'required', e.target.checked)} />}
                label="Required?"
              />
              {isMobile ? (
                <Button
                  onClick={() => handleRemoveCustomTeamField(index)}
                  variant="outlined"
                  color="error"
                  size="small"
                  sx={{ alignSelf: 'flex-start' }}  // Align button to top when in column mode
                >
                  Delete Field
                </Button>
              ) : (
                <IconButton onClick={() => handleRemoveCustomTeamField(index)} size="small">
                  <CloseIcon />
                </IconButton>
              )}
            </Stack>
          ))}

          <br />
          <Button onClick={addCustomTeamField} variant="outlined">Add Team Field</Button>
        </TabPanel>

        {/* Catches Tab */}
        <TabPanel value={activeTab} index={2}>
          <h3>Catch Settings</h3>
          <p>Define your event species and preferred scoring system.</p>
          <br />
          {tier === "Tier 1" ? (
            <p><strong>Max number of catches allowed ({tier}):</strong> 250</p>
          ) : (
            <p><strong>Max number of catches allowed ({tier}):</strong> Unlimited</p>
          )}
          <br />

          {/* Display Rows for Catches */}
          {catchRows.map((catchRow, index) => (
            <Stack
              direction={isMobile ? "column" : "row"}
              spacing={2}
              sx={{ mt: 2, borderBottom: isMobile ? '1px solid #ddd' : 'none', pb: isMobile ? 2 : 0 }}
              key={`catch-${index}`}
            >
              {/* Species Name */}
              <TextField
                label="Species"
                value={catchRow.species}
                onChange={(e) => handleCatchAttributeChange(e, index, 'species')}
                fullWidth
              />

              {/* Scoring Select Menu */}
              <FormControl fullWidth>
                <InputLabel>Scoring</InputLabel>
                <Select
                  label="Scoring"
                  value={catchRow.scoring}
                  onChange={(e) => handleCatchAttributeChange(e, index, 'scoring')}
                >
                  <MenuItem value="Catch & Release (Tiebreaker: Earliest Time)">Catch & Release (Tiebreaker: Earliest Time)</MenuItem>
                  <MenuItem value="Weight Only (Tiebreaker: None)">Weight Only (Tiebreaker: None)</MenuItem>
                  <MenuItem value="Weight (Tiebreaker: Length)">Weight (Tiebreaker: Length)</MenuItem>
                  <MenuItem value="Weight (Tiebreaker: Length, Girth)">Weight (Tiebreaker: Length, Girth)</MenuItem>
                  <MenuItem value="Length Only (Tiebreaker: None)">Length Only (Tiebreaker: None)</MenuItem>
                  <MenuItem value="Length (Tiebreaker: Weight)">Length (Tiebreaker: Weight)</MenuItem>
                  <MenuItem value="Length (Tiebreaker: Girth)">Length (Tiebreaker: Girth)</MenuItem>
                </Select>
              </FormControl>

              {/* Points Field (Only visible if type is 'Catch & Release (Tiebreaker: Earliest Time)') */}
              {catchRow.scoring === 'Catch & Release (Tiebreaker: Earliest Time)' ? (
                <TextField
                  label="Points"
                  type="number"
                  value={catchRow.points}
                  onChange={(e) => handleCatchAttributeChange(e, index, 'points')}
                  fullWidth
                />
              ) : (
                <Box display="flex" alignItems="center" justifyContent="center" sx={{ width: '100%' }}>
                  <p>-</p>
                </Box>
              )}

              {/* Delete Button */}
              {isMobile ? (
                <Button
                  onClick={() => handleRemoveCatchRow(index)}
                  variant="outlined"
                  color="error"
                  size="small"
                  sx={{ alignSelf: 'flex-start' }}
                >
                  Delete Row
                </Button>
              ) : (
                <IconButton onClick={() => handleRemoveCatchRow(index)} size="small">
                  <CloseIcon />
                </IconButton>
              )}
            </Stack>
          ))}

          <br />
          {/* Add Button to add a new row for catches */}
          <Button onClick={handleAddCatchRow} variant="outlined">Add Species</Button>
        </TabPanel>

        {/* Registration Tab */}
        <TabPanel value={activeTab} index={3}>
          <h3>Registration Settings</h3>
          <p>Integrated online registration is available for Tier 3 and Tier 4 events.</p>
        </TabPanel>

        {/* Pots Tab */}
        <TabPanel value={activeTab} index={4}>
          <h3>Pot Settings</h3>
          <p>Integrated betting pots (e.g. calcutta) are available for Tier 3 and Tier 4 events.</p>
        </TabPanel>

        {/* Payment Tab */}
        <TabPanel value={activeTab} index={5}>
          <h3>Payment</h3>
          <p>Payment settings and configurations will be managed here...</p>
          <p>Implement any user add-ons or company-specific upsell fields as needed.</p>
          <br/>
          {/* Display missing fields in red text */}
          {missingFields.length > 0 && (
            <Box sx={{ color: 'red', mb: 2 }}>
              <strong>Missing Fields:</strong>
              <ul>
                {missingFields.map((field, index) => (
                  <li key={index}>{field}</li>
                ))}
              </ul>
            </Box>
          )}
          <Button variant="contained" color="primary" onClick={handleCreateEventAsAdmin} disabled={isSubmitting}>
            {isSubmitting ? 'Submitting...' : 'Create Event as Admin (Dev Only)'}
          </Button>
        </TabPanel>

        {/* Navigation Buttons */}
        <Stack direction="row" justifyContent="space-between" sx={{ mt: 2 }}>
          <Button variant="contained" color="secondary" onClick={handleBack} disabled={activeTab === 0}>
            Back
          </Button>
          <Button variant="contained" color="primary" onClick={handleNext} disabled={activeTab === 5}>
            Next
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default CreateEventModal;

