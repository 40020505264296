import React, {useState, useEffect} from 'react';
import { useParams } from 'react-router-dom';
import { auth } from '../firebase';
import { signInWithEmailAndPassword, signOut } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import dayjs from 'dayjs';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import CircularProgress from '@mui/material/CircularProgress';

import AnimatedPage from './AnimatedPage';
import CrudTable from '../components/tables/CrudTable';
import Footer from '../components/footer/Footer';
import { fetchAndGenerateRegistrationReport } from '../generators/registrationReports';
import { fetchAndGenerateCatchesByTeamReport, fetchAndGenerateCatchesBySpeciesReport } from '../generators/catchesReports';
import { generateLeaderboardReport } from '../generators/leaderboardReports';

import { teamsConfig, catchesConfig, announcementsConfig } from "../config/config";
import "./HomePage.css";
import EventAuthenticate from '../components/authenticate/EventAuthenticate';

function AdminPage() {   

  // STATE - AUTH
  const navigate = useNavigate();

  // STATE - GENERAL
  const { username, eventId } = useParams(); // Get username and eventId from the URL
  const [eventSettings, setEventSettings] = useState(null);
  const [loading, setLoading] = useState(true);
  const [tabLoading, setTabLoading] = useState(true); // Tab-specific loading state
  const currentUser = JSON.parse(window.localStorage.getItem('user'));    // login status
  const theme = useTheme();    // device size
  const matches = useMediaQuery(theme.breakpoints.up("md"));  
  const isMobile = !matches; // Detect if the user is on mobile
  const today = new Date();
  const desktopScroll = null;
  const mobileScroll = 'scroll';
  const [style, setStyle] = useState();
  const [initialState, setInitialState] = useState();
  const [pageSizeOptions, setPageSizeOptions] = useState();
  const [tabName, setTabName] = useState(window.localStorage.getItem('selectedTab') || "Catches"); 
  const tabNameList = ["Teams", "Catches", "Announcements", "Stats", "Reports"]; 
  const [tableProperties, setTableProperties] = useState([])

  // STATE - TEAMS
  const [teamRows, setTeamRows] = useState([]);
  const [teamRowsHaveLoaded, setTeamRowsHaveLoaded] = useState(false);
  const [isAddTeamModalOpen, setIsAddTeamModalOpen] = useState(false);
  const [isDeleteTeamModalOpen, setIsDeleteTeamModalOpen] = useState(false);
  const [isEditTeamModalOpen, setIsEditTeamModalOpen] = useState(false);
  const [deleteTeamInfo, setDeleteTeamInfo] = useState();
  const [editTeamInfo, setEditTeamInfo] = useState();
  const openAddTeamModal = () => {setIsAddTeamModalOpen(true)};
  const closeAddTeamModal = () => {setIsAddTeamModalOpen(false)};
  const openEditTeamModal = () => {setIsEditTeamModalOpen(true)};
  const closeEditTeamModal = () => {setIsEditTeamModalOpen(false)};
  const openDeleteTeamModal = () => {setIsDeleteTeamModalOpen(true)};
  const closeDeleteTeamModal = () => {setIsDeleteTeamModalOpen(false)};

  // STATE - CATCHES
  const [catchRows, setCatchRows] = useState([]);
  const [catchRowsHaveLoaded, setCatchRowsHaveLoaded] = useState(false);
  const [isAddCatchModalOpen, setIsAddCatchModalOpen] = useState(false);
  const [isDeleteCatchModalOpen, setIsDeleteCatchModalOpen] = useState(false);
  const [isEditCatchModalOpen, setIsEditCatchModalOpen] = useState(false);
  const [deleteCatchInfo, setDeleteCatchInfo] = useState();
  const [editCatchInfo, setEditCatchInfo] = useState();
  const openAddCatchModal = () => {setIsAddCatchModalOpen(true)};
  const closeAddCatchModal = () => {setIsAddCatchModalOpen(false)};
  const openEditCatchModal = () => {setIsEditCatchModalOpen(true)};
  const closeEditCatchModal = () => {setIsEditCatchModalOpen(false)};
  const openDeleteCatchModal = () => {setIsDeleteCatchModalOpen(true)};
  const closeDeleteCatchModal = () => {setIsDeleteCatchModalOpen(false)};

  // STATE - ANNOUNCEMENTS
  const [announcementRows, setAnnouncementRows] = useState([]);
  const [announcementRowsHaveLoaded, setAnnouncementRowsHaveLoaded] = useState(false);
  const [isAddAnnouncementModalOpen, setIsAddAnnouncementModalOpen] = useState(false);
  const [isDeleteAnnouncementModalOpen, setIsDeleteAnnouncementModalOpen] = useState(false);
  const [isEditAnnouncementModalOpen, setIsEditAnnouncementModalOpen] = useState(false);
  const [deleteAnnouncementInfo, setDeleteAnnouncementInfo] = useState();
  const [editAnnouncementInfo, setEditAnnouncementInfo] = useState();
  const openAddAnnouncementModal = () => {setIsAddAnnouncementModalOpen(true)};
  const closeAddAnnouncementModal = () => {setIsAddAnnouncementModalOpen(false)};
  const openEditAnnouncementModal = () => {setIsEditAnnouncementModalOpen(true)};
  const closeEditAnnouncementModal = () => {setIsEditAnnouncementModalOpen(false)};
  const openDeleteAnnouncementModal = () => {setIsDeleteAnnouncementModalOpen(true)};
  const closeDeleteAnnouncementModal = () => {setIsDeleteAnnouncementModalOpen(false)};

  // STATE - STATS
  const [statsData, setStatsData] = useState({});

  // STATE - REPORTS
  const [isRegistrationReportLoading, setIsRegistrationReportLoading] = useState(false);
  const [isCatchesSpeciesReportLoading, setIsCatchesSpeciesReportLoading] = useState(false);
  const [isCatchesTeamReportLoading, setIsCatchesTeamReportLoading] = useState(false);
  const [isLeaderboardReportLoading, setIsLeaderboardReportLoading] = useState(false);

  // INITIALIZE
  useEffect(() => {
    const apiUrl = process.env.REACT_APP_NODE_ENV === 'production'
      ? process.env.REACT_APP_SERVER_URL_PRODUCTION
      : process.env.REACT_APP_SERVER_URL_STAGING;

    // Fetch event settings
    const fetchEventSettings = async () => {
      try {
        const response = await fetch(`${apiUrl}/api/admin_get_event_settings`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ username, eventId }),
        });
        const data = await response.json();
        if (response.ok && data.success) {
          console.log(data)
          setEventSettings(data.settings); // Store settings in state
        } else {
          console.error('Error fetching event settings 1:', data.message);
        }
      } catch (error) {
        console.error('Error fetching event settings: 2', error);
      } finally {
        setLoading(false); // Set loading to false after fetching settings
      }
    };
    fetchEventSettings();
  }, [username, eventId]);

  useEffect(() => {
    const selectedTab = tabName || "Catches";  // Ensure fallback to the default tab
    fetchTabData(selectedTab); // Load config and fetch data
  }, [tabName]);  // Refetch when the tab changes

  const fetchTabData = async (tab) => {
    setTabLoading(true); // Start loading for tab data
    const apiUrl = process.env.REACT_APP_NODE_ENV === 'production'
      ? process.env.REACT_APP_SERVER_URL_PRODUCTION
      : process.env.REACT_APP_SERVER_URL_STAGING;

    let tempRows = [];
    try {
      if (tab === "Teams") {
        const response = await fetch(`${apiUrl}/api/admin_get_event_teams`, {
          method: 'POST',
          headers: {'Content-Type': 'application/json'},
          body: JSON.stringify({ username, eventId }) 
        });
        const teamsData = await response.json();
        tempRows = Object.values(teamsData); // Transform teams data into rows format if necessary
        setTeamRows(tempRows);
        setTeamRowsHaveLoaded(true);
      } else if (tab === "Catches") {
        const response = await fetch(`${apiUrl}/api/admin_get_event_catches`, {
          method: 'POST',
          headers: {'Content-Type': 'application/json'},
          body: JSON.stringify({ username, eventId }) 
        });
        const catchesData = await response.json();
        tempRows = Object.values(catchesData); // Transform catches data into rows format if necessary
        console.log("catches data", tempRows);
        setCatchRows(tempRows);
        setCatchRowsHaveLoaded(true);
      } else if (tab === "Announcements") {
        const response = await fetch(`${apiUrl}/api/admin_get_event_announcements`, {
          method: 'POST',
          headers: {'Content-Type': 'application/json'},
          body: JSON.stringify({ username, eventId }) 
        });
        const announcementsData = await response.json();
        tempRows = Object.values(announcementsData); // Transform announcements data into rows format if necessary
        setAnnouncementRows(tempRows);
        setAnnouncementRowsHaveLoaded(true);
      } else if (tab === "Stats") {
        const response = await fetch(`${apiUrl}/api/admin_get_event_stats`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ username, eventId })
        });
        const statsData = await response.json();
        if (response.ok) {
          console.log('Stats data:', statsData);
          setStatsData(statsData);  // Store the stats in state
        } else {
          console.error('Error fetching stats:', statsData.error);
        }
      }
    } catch (error) {
      console.error(`Error fetching ${tab} data:`, error);
    } finally {
      setTabLoading(false); // Set tab-specific loading to false after data is fetched
    }
  };

  // HELPERS
  const formatCurrency = (value) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(value);
  };

  const handleTabChange = (event, newTab) => {
    setTabName(newTab);
    window.localStorage.setItem('selectedTab', newTab); // Save the selected tab to local storage
    fetchTabData(newTab);
  };

  const delayRefresh = () => {
    setTimeout(() => {
      console.log('Delaying page refresh...');
      window.location.reload();
    }, 2000);
  }

  const validateEmail = (email) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };

  // AUTHENTICATION
  const handleLogin = (e, email, password) => {
    const apiUrl = process.env.REACT_APP_NODE_ENV === 'production'
      ? process.env.REACT_APP_SERVER_URL_PRODUCTION
      : process.env.REACT_APP_SERVER_URL_STAGING;

    if (!validateEmail(email)) {
      toast.error("Please enter a valid email.");
      return;
    }
    if (!password) {
      toast.error("Please enter your password.");
      return;
    }

    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const user = userCredential.user;

        // Load user profile from server
        fetch(`${apiUrl}/api/load_user_profile`, {
          method: 'POST',
          headers: {'Content-Type': 'application/json'},
          body: JSON.stringify({ userId: user.uid })
        })
        .then(res => res.json())
        .then(data => {
          const username = data.username;
          console.log(typeof username)
          console.log(username)
          window.localStorage.setItem('user', JSON.stringify(user));
          window.localStorage.setItem('username', username); // Save username in localStorage
          toast.success("Login successful! Redirecting...", { autoClose: 2000 });
          window.location.reload();
          // setTimeout(() => navigate(`/${username}/dashboard`), 2000);  
        })
        .catch(e => {
          console.error(e);
          toast.error("Failed to load user profile.");
        });
      })
      .catch((error) => {
        if (error.code === 'auth/wrong-password') {
          toast.error("Incorrect password. Please try again.");
        } else if (error.code === 'auth/user-not-found') {
          toast.error("Email not found. Please sign up first.");
        } else {
          toast.error("Error logging in: " + error.message);
        }
      });
  };

  const handleLogout = (e) => {
    signOut(auth)
      .then(() => {
        window.localStorage.setItem('user', null);
        window.localStorage.setItem('username', null);
        toast.success('Logout successful! Redirecting...', { autoClose: 2000 });
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch((error) => {
        toast.error("There was an error while attempting to log you out. Please contact the site administrator.");
      });
  };

  // REPORT GENERATORS
  const handleGenerateRegistrationReport = async () => {
    setIsRegistrationReportLoading(true); // Set the loading state
    try {
      console.log('In handleGenerateRegistrationReport...');
      await fetchAndGenerateRegistrationReport(
        username,
        eventId,
        teamsConfig, // Pass the table properties
        eventSettings.eventTitle
      );
    } catch (error) {
      console.error('Error generating registration report:', error);
    } finally {
      setIsRegistrationReportLoading(false); // Reset the loading state after completion
    }
  };

  const handleGenerateCatchesReportSpecies = async () => {
    setIsCatchesSpeciesReportLoading(true);
    try {
      console.log('In handleGenerateCatchesReportSpecies...');
      await fetchAndGenerateCatchesBySpeciesReport(
        username,
        eventId,
        eventSettings.eventTitle
      );
    } catch (error) {
      console.error('Error generating catches report (Species):', error);
    } finally {
      setIsCatchesSpeciesReportLoading(false);
    }
  };  

  const handleGenerateCatchesReportTeams = async () => {
    setIsCatchesTeamReportLoading(true);
    try {
      console.log('In handleGenerateCatchesReportTeams...');
      await fetchAndGenerateCatchesByTeamReport(
        username,
        eventId,
        eventSettings.eventTitle
      );
    } catch (error) {
      console.error('Error generating catches report (Teams):', error);
    } finally {
      setIsCatchesTeamReportLoading(false);
    }
  };

  const handleGenerateLeaderboardReport = async () => {
    setIsLeaderboardReportLoading(true);
    try {
      console.log('In handleGenerateLeaderboardReport...');
      await generateLeaderboardReport(
        username,
        eventId,
        eventSettings.eventTitle
      );
    } catch (error) {
      console.error('Error generating leaderboard report:', error);
    } finally {
      setIsLeaderboardReportLoading(false);
    }
  };

  if (loading || tabLoading || !eventSettings) {
    return <div>Loading...</div>;
  };

  return (
    <AnimatedPage>
      <main>

        {/* BANNER */}
        <section style={{ backgroundColor: eventSettings.backgroundColor }} className="section-banner">
          <h1 style={{ color: eventSettings.headlineColor }}>Settings</h1>
        </section>

        <section className="section-logout">

          {(!(currentUser === undefined) && !(currentUser === null)) && 
            <Box sx={{ width: '90%', typography: 'body1' }}>

              <p style={{color: "black"}}>{`You are currently logged in as: ${currentUser.email}`}</p>
              <br/>

              <Button onClick={handleLogout} color="primary" variant="contained" fullwidth >Logout</Button>  
              <br/>
              <br/>

              <br/>
              <TabContext value={tabName}>

                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <TabList variant="scrollable" onChange={handleTabChange} aria-label="lab API tabs example">
                    {tabNameList.map((tab) => (
                      <Tab key={tab} label={tab} value={tab} />
                    ))}
                  </TabList>
                </Box>

                {tabNameList.map((tab) => {
                  if (tab === "Stats") {
                    return (
                      <TabPanel key="Stats" value="Stats">
                        {tabLoading || !statsData || !statsData.catchesBySpecies ? (
                          <CircularProgress />
                        ) : (
                          <div>
                            <h3>Teams</h3>
                            <p>Total Registered: {statsData.totalTeams} ({statsData.checkedInTeams} checked-in)</p>
                            <br/>

                            <h3>Catches</h3>
                            {Object.keys(statsData.catchesBySpecies).length > 0 ? (
                              <>
                                {Object.entries(statsData.catchesBySpecies).map(([species, count]) => (
                                  <p key={species}>{species}: {count}</p>
                                ))}
                                <p><strong>Total:</strong> {statsData.totalCatches}</p>
                              </>
                            ) : (
                              <p>No catches by species data available.</p>
                            )}
                          </div>
                        )}
                      </TabPanel>
                    );
                  } else if (tab === "Reports") {
                    return (
                      <TabPanel key="Reports" value="Reports">
                        <div>
                          <div>
                            <Button
                              onClick={() => handleGenerateRegistrationReport()}
                              color="primary"
                              variant="contained"
                              disabled={isRegistrationReportLoading} // Disable while loading
                            >
                              {isRegistrationReportLoading ? "Processing..." : "Download Registration Form"}
                            </Button>
                            <br /><br />
                          </div>

                          <div>
                            <Button
                              onClick={() => handleGenerateCatchesReportSpecies()}
                              color="primary"
                              variant="contained"
                              disabled={isCatchesSpeciesReportLoading}
                            >
                              {isCatchesSpeciesReportLoading ? 'Processing...' : 'Download Catch Log (Species)'}
                            </Button>
                            <br /><br />
                            <Button
                              onClick={() => handleGenerateCatchesReportTeams()}
                              color="primary"
                              variant="contained"
                              disabled={isCatchesTeamReportLoading}
                            >
                              {isCatchesTeamReportLoading ? 'Processing...' : 'Download Catch Log (Teams)'}
                            </Button>
                            <br /><br />
                          </div>

                          <div>
                            <Button
                              onClick={() => handleGenerateLeaderboardReport()}
                              color="primary"
                              variant="contained"
                              disabled={isLeaderboardReportLoading}
                            >
                              {isLeaderboardReportLoading ? 'Processing...' : 'Download Leaderboard'}
                            </Button>
                            <br /><br />
                          </div>

                        </div>
                      </TabPanel>
                    );
                  } else if (tab === "Teams") {
                    return (
                      <TabPanel key={tab} value={tab}>
                        {!teamRowsHaveLoaded ? (
                          <CircularProgress />
                        ) : (
                          <div style={style}> 
                            <CrudTable
                              eventSettings={eventSettings}
                              today={today}
                              startDate={eventSettings.eventStartDateTimeGMT}
                              endDate={eventSettings.eventEndDateTimeGMT}
                              tableType={tab}
                              buttonLabel={`Add ${tab}`}
                              tableProperties={tableProperties}
                              style={style}
                              rows={teamRows || []}
                              scroll={matches ? desktopScroll : mobileScroll}
                              initialState={initialState}
                              pageSizeOptions={pageSizeOptions}
                              checkboxSelection={true}
                              addStatus={isAddTeamModalOpen}
                              openAddModal={openAddTeamModal}
                              closeAddModal={closeAddTeamModal}
                              editStatus={isEditTeamModalOpen}
                              editInfo={editTeamInfo}
                              setEditInfo={setEditTeamInfo}
                              openEditModal={openEditTeamModal}
                              closeEditModal={closeEditTeamModal}
                              deleteStatus={isDeleteTeamModalOpen}
                              deleteInfo={deleteTeamInfo}
                              setDeleteInfo={setDeleteTeamInfo}
                              openDeleteModal={openDeleteTeamModal}
                              closeDeleteModal={closeDeleteTeamModal}
                            />
                          </div>
                        )}
                      </TabPanel>
                    );
                  } else if (tab === "Catches") {
                    return (
                      <TabPanel key={tab} value={tab}>
                        {!catchRowsHaveLoaded ? (
                          <CircularProgress />
                        ) : (
                          <div style={style}> 
                            <CrudTable
                              eventSettings={eventSettings}
                              today={today}
                              startDate={eventSettings.eventStartDateTimeGMT}
                              endDate={eventSettings.eventEndDateTimeGMT}
                              tableType={tab}
                              buttonLabel={`Add ${tab}`}
                              tableProperties={tableProperties}
                              style={style}
                              rows={catchRows || []}
                              scroll={matches ? desktopScroll : mobileScroll}
                              initialState={initialState}
                              pageSizeOptions={pageSizeOptions}
                              checkboxSelection={true}
                              addStatus={isAddCatchModalOpen}
                              openAddModal={openAddCatchModal}
                              closeAddModal={closeAddCatchModal}
                              editStatus={isEditCatchModalOpen}
                              editInfo={editCatchInfo}
                              setEditInfo={setEditCatchInfo}
                              openEditModal={openEditCatchModal}
                              closeEditModal={closeEditCatchModal}
                              deleteStatus={isDeleteCatchModalOpen}
                              deleteInfo={deleteCatchInfo}
                              setDeleteInfo={setDeleteCatchInfo}
                              openDeleteModal={openDeleteCatchModal}
                              closeDeleteModal={closeDeleteCatchModal}
                            />
                          </div>
                        )}
                      </TabPanel>
                    );
                  } else if (tab === "Announcements") {
                    return (
                      <TabPanel key={tab} value={tab}>
                        {!announcementRowsHaveLoaded ? (
                          <CircularProgress />
                        ) : (
                          <div style={style}> 
                            <CrudTable
                              eventSettings={eventSettings}
                              today={today}
                              startDate={eventSettings.eventStartDateTimeGMT}
                              endDate={eventSettings.eventEndDateTimeGMT}
                              tableType={tab}
                              buttonLabel={`Add ${tab}`}
                              tableProperties={tableProperties}
                              style={style}
                              rows={announcementRows || []}
                              scroll={matches ? desktopScroll : mobileScroll}
                              initialState={initialState}
                              pageSizeOptions={pageSizeOptions}
                              checkboxSelection={true}
                              addStatus={isAddAnnouncementModalOpen}
                              openAddModal={openAddAnnouncementModal}
                              closeAddModal={closeAddAnnouncementModal}
                              editStatus={isEditAnnouncementModalOpen}
                              editInfo={editAnnouncementInfo}
                              setEditInfo={setEditAnnouncementInfo}
                              openEditModal={openEditAnnouncementModal}
                              closeEditModal={closeEditAnnouncementModal}
                              deleteStatus={isDeleteAnnouncementModalOpen}
                              deleteInfo={deleteAnnouncementInfo}
                              setDeleteInfo={setDeleteAnnouncementInfo}
                              openDeleteModal={openDeleteAnnouncementModal}
                              closeDeleteModal={closeDeleteAnnouncementModal}
                            />
                          </div>
                        )}
                      </TabPanel>
                    );
                  } 

                })}

              </TabContext>

            </Box> 
          }

          {(currentUser === undefined || currentUser === null) && <EventAuthenticate handleLogin={handleLogin} handleLogout={handleLogout} />}  
        
        </section>

        <Footer/>
      </main>
    </AnimatedPage>
  );
}

export default AdminPage;

