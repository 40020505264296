import { Navigate } from "react-router-dom";
import MainLayout from "../layouts/MainLayout";
import DashboardPage from "../pages/DashboardPage";
import ProfilePage from '../pages/ProfilePage';
import StatisticsPage from "../pages/StatisticsPage";
import ErrorPage from "../pages/ErrorPage";
import EventLayout from "../layouts/EventLayout";
import HomePage from "../pages/HomePage";
import AdminPage from "../pages/AdminPage";
import NewsfeedPage from "../pages/NewsfeedPage";
import LeaderboardPage from "../pages/LeaderboardPage";
import Authenticate from "../components/authenticate/Authenticate";
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import BarChartIcon from '@mui/icons-material/BarChart';
import EventIcon from '@mui/icons-material/Event';

// Private Route Component to protect routes
const PrivateRoute = ({ element }) => {
  const currentUser = JSON.parse(window.localStorage.getItem('user'));
  return currentUser ? element : <Navigate to="/" replace />;
};

// Routes definition
export const appRoutes = [
  {
    path: '/',
    element: <Authenticate />, // Default to the Authenticate component
    errorElement: <ErrorPage />,
  },
  {
    path: '/:username/profile',
    element: <PrivateRoute element={<MainLayout />} />, // Use PrivateRoute for MainLayout
    children: [
      { index: true, element: <ProfilePage /> },
    ],
    errorElement: <ErrorPage />,
    state: 'profile',
    sidebarProps: {
      displayText: "Profile",
      icon: <ManageAccountsIcon />
    }
  },
  {
    path: '/:username/dashboard',
    element: <PrivateRoute element={<MainLayout />} />, // Use PrivateRoute for MainLayout
    children: [
      { index: true, element: <DashboardPage /> },
    ],
    errorElement: <ErrorPage />,
    state: "dashboard",
    sidebarProps: {
      displayText: "My Events",
      icon: <EventIcon />
    }
  },
  {
    path: '/:username/statistics',
    element: <PrivateRoute element={<MainLayout />} />, // Use PrivateRoute for MainLayout
    children: [
      { index: true, element: <StatisticsPage /> },
    ],
    errorElement: <ErrorPage />,
    state: "statistics",
    sidebarProps: {
      displayText: "Statistics",
      icon: <BarChartIcon />
    }
  },
  {
    path: '*',
    element: <ErrorPage />,
  },
  {
    path: '/:username/:eventId', 
    element: <EventLayout />, 
    errorElement: <ErrorPage />,
    children: [
      { path: 'home', element: <HomePage /> }, 
      { path: 'admin', element: <AdminPage /> }, 
      { path: 'newsfeed', element: <NewsfeedPage /> }, 
      { path: 'leaderboard', element: <LeaderboardPage /> }, 
    ],
  },
];

