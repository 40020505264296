import React from 'react';
import AnimatedPage from './AnimatedPage';

const StatisticsPage = (props) => {
  return (
    <AnimatedPage>
      <div>Statistics Page</div>
    </AnimatedPage>
  );
};

export default StatisticsPage;

