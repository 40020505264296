export const sizeConfig = {
  sidebar: {
    width: "300px"
  }
};

export const colorConfig = {
  sidebar: {
    bg: "#233044",
    color: "#eeeeee",
    hoverBg: "#1e293a",
    activeBg: "#1e253a"
  },
  topbar: {
    bg: '#ffffff',
    color: "#000"
  },
  // mainBg: colors.grey["100"],
  mainBg:'#ffffff',
  authenticateBg: 'grey',
};

export const teamsConfig = [
  {
    field: "teamId",
    headerName: "Team ID",
    type: "string",
    isEditable: false,
    isRequired: true,
    isVisible: false,
    flex: 3,
    width: 300,
    isId: true,
    isCurrency: false,
    isPhoneNumber: false,
    isEmail: false,
    isDateTime: false,
    isImage: false,
    isAddOnCharge: false,
    headerClassName: "super-app-theme--header",
    headerAlign: "center",
    align: "center",
  },
  {
    field: "Team name",
    headerName: "Name",
    type: "string",
    isEditable: true,
    isRequired: true,
    isVisible: true,
    flex: 3,
    width: 300,
    isId: false,
    isCurrency: false,
    isPhoneNumber: false,
    isEmail: false,
    isDateTime: false,
    isImage: false,
    isAddOnCharge: false,
    headerClassName: "super-app-theme--header",
    headerAlign: "center",
    align: "center",
  },
  {
    field: "Team email",
    headerName: "Email",
    type: "string",
    isEditable: true,
    isRequired: true,
    isVisible: true,
    flex: 4,
    width: 400,
    isId: false,
    isPhoneNumber: false,
    isCurrency: false,
    isEmail: true,
    isDateTime: false,
    isImage: false,
    isAddOnCharge: false,
    headerClassName: "super-app-theme--header",
    headerAlign: "center",
    align: "center",
  },
  {
    field: "Team phone",
    headerName: "Phone",
    type: "string",
    isEditable: true,
    isRequired: true,
    isVisible: true,
    flex: 2,
    width: 200,
    isId: false,
    isPhoneNumber: true,
    isCurrency: false,
    isEmail: false,
    isDateTime: false,
    isImage: false,
    isAddOnCharge: false,
    headerClassName: "super-app-theme--header",
    headerAlign: "center",
    align: "center",
  },
  {
    field: "hasCheckedIn",
    headerName: "Checked-In?",
    type: "boolean",
    isEditable: true,
    isRequired: true,
    isVisible: true,
    flex: 2,
    width: 200,
    isId: false,
    isCurrency: false,
    isPhoneNumber: true,
    isEmail: false,
    isDateTime: false,
    isImage: false,
    isAddOnCharge: false,
    headerClassName: "super-app-theme--header",
    headerAlign: "center",
    align: "center",
  },
];

export const catchesConfig = [
  {
    field: "catchId",
    headerName: "Catch ID",
    type: "string",
    isEditable: false,
    isRequired: true,
    isVisible: false,
    flex: 3,
    width: 300,
    isId: true,
    isCurrency: false,
    isPhoneNumber: false,
    isEmail: false,
    isDateTime: false,
    isImage: false,
    isAddOnCharge: false,
    headerClassName: "super-app-theme--header",
    headerAlign: "center",
    align: "center",
  },
  {
    field: "teamId",
    headerName: "Team ID",
    type: "string",
    isEditable: false,
    isRequired: true,
    isVisible: false,
    flex: 3,
    width: 300,
    isId: true,
    isCurrency: false,
    isPhoneNumber: false,
    isEmail: false,
    isDateTime: false,
    isImage: false,
    isAddOnCharge: false,
    headerClassName: "super-app-theme--header",
    headerAlign: "center",
    align: "center",
  },
  {
    field: "species",
    headerName: "Species",
    type: "string",
    isEditable: false,
    isRequired: true,
    isVisible: true,
    flex: 2,
    width: 200,
    isId: false,
    isPhoneNumber: false,
    isCurrency: false,
    isEmail: false,
    isDateTime: false,
    isImage: false,
    isAddOnCharge: false,
    headerClassName: "super-app-theme--header",
    headerAlign: "center",
    align: "center",
  },
  {
    field: "speciesType",
    headerName: "Type",
    type: "string",
    isEditable: false,
    isRequired: true,
    isVisible: true,
    flex: 2,
    width: 200,
    isId: false,
    isPhoneNumber: false,
    isCurrency: false,
    isEmail: false,
    isDateTime: false,
    isImage: false,
    isAddOnCharge: false,
    headerClassName: "super-app-theme--header",
    headerAlign: "center",
    align: "center",
  },
  {
    field: "teamName",
    headerName: "Team",
    type: "string",
    isEditable: false,
    isRequired: true,
    isVisible: true,
    flex: 3,
    width: 300,
    isId: false,
    isCurrency: false,
    isPhoneNumber: false,
    isEmail: false,
    isDateTime: false,
    isImage: false,
    isAddOnCharge: false,
    headerClassName: "super-app-theme--header",
    headerAlign: "center",
    align: "center",
  },
  {
    field: "weight",
    headerName: "Weight (lbs)",
    type: "number",
    isEditable: true,
    isRequired: false,
    isVisible: true,
    flex: 2,
    width: 200,
    isId: false,
    isCurrency: false,
    isPhoneNumber: false,
    isEmail: false,
    isDateTime: false,
    isImage: false,
    isAddOnCharge: false,
    headerClassName: "super-app-theme--header",
    headerAlign: "center",
    align: "center",
  },
  {
    field: "length",
    headerName: "Length (in)",
    type: "number",
    isEditable: true,
    isRequired: false,
    isVisible: true,
    flex: 2,
    width: 200,
    isId: false,
    isCurrency: false,
    isPhoneNumber: false,
    isEmail: false,
    isDateTime: false,
    isImage: false,
    isAddOnCharge: false,
    headerClassName: "super-app-theme--header",
    headerAlign: "center",
    align: "center",
  },
  {
    field: "girth",
    headerName: "Girth (in)",
    type: "number",
    isEditable: true,
    isRequired: false,
    isVisible: true,
    flex: 2,
    width: 200,
    isId: false,
    isCurrency: false,
    isPhoneNumber: false,
    isEmail: false,
    isDateTime: false,
    isImage: false,
    isAddOnCharge: false,
    headerClassName: "super-app-theme--header",
    headerAlign: "center",
    align: "center",
  },
  {
    field: "points",
    headerName: "Points",
    type: "number",
    isEditable: false,
    isRequired: false,
    isVisible: true,
    flex: 2,
    width: 200,
    isId: false,
    isCurrency: false,
    isPhoneNumber: false,
    isEmail: false,
    isDateTime: false,
    isImage: false,
    isAddOnCharge: false,
    headerClassName: "super-app-theme--header",
    headerAlign: "center",
    align: "center",
  },
  {
    field: "dateTime",
    headerName: "Time of Catch",
    type: "dateTime",
    isEditable: true,
    isRequired: true,
    isVisible: true,
    flex: 3,
    width: 300,
    isId: false,
    isCurrency: false,
    isPhoneNumber: false,
    isEmail: false,
    isDateTime: true,
    isImage: false,
    isAddOnCharge: false,
    headerClassName: "super-app-theme--header",
    headerAlign: "center",
    align: "center",
  },
];

export const announcementsConfig = [
  {
    field: "announcementId",
    headerName: "ID",
    type: "string",
    isEditable: false,
    isRequired: true,
    isVisible: false,
    flex: 1,
    width: 200,
    isId: true,
    isCurrency: false,
    isPhoneNumber: false,
    isEmail: false,
    isDateTime: false,
    isImage: false,
    isAddOnCharge: false,
    headerClassName: "super-app-theme--header",
    headerAlign: "center",
    align: "center",
  },
  {
    field: "subject",
    headerName: "Subject",
    type: "string",
    isEditable: false,
    isRequired: true,
    isVisible: true,
    flex: 1,
    width: 200,
    isId: true,
    isCurrency: false,
    isPhoneNumber: false,
    isEmail: false,
    isDateTime: false,
    isImage: false,
    isAddOnCharge: false,
    headerClassName: "super-app-theme--header",
    headerAlign: "center",
    align: "center",
  },    
  {
    field: "subtitle",
    headerName: "Message",
    type: "string",
    isEditable: false,
    isRequired: true,
    isVisible: true,
    flex: 1,
    width: 200,
    isId: true,
    isCurrency: false,
    isPhoneNumber: false,
    isEmail: false,
    isDateTime: false,
    isImage: false,
    isAddOnCharge: false,
    headerClassName: "super-app-theme--header",
    headerAlign: "center",
    align: "center",
  },    
  {
    field: "hyperlink",
    headerName: "Hyperlink",
    type: "string",
    isEditable: false,
    isRequired: true,
    isVisible: true,
    flex: 1,
    width: 200,
    isId: true,
    isCurrency: false,
    isPhoneNumber: false,
    isEmail: false,
    isDateTime: false,
    isImage: false,
    isAddOnCharge: false,
    headerClassName: "super-app-theme--header",
    headerAlign: "center",
    align: "center",
  },    
  {
    field: "timestamp",
    headerName: "Time Created",
    type: "dateTime",
    isEditable: false,
    isRequired: true,
    isVisible: true,
    flex: 1,
    width: 200,
    isId: false,
    isCurrency: false,
    isPhoneNumber: false,
    isEmail: false,
    isDateTime: true,
    isImage: false,
    isAddOnCharge: false,
    headerClassName: "super-app-theme--header",
    headerAlign: "center",
    align: "center",
  },    
];

// Leaderboard display column definitions
export const catchAndReleaseDesktopColumns = [
  { field: 'place', headerName: 'Place', flex: 1, headerClassName: 'super-app-theme--header', headerAlign: 'center', align: 'center', isDateTime: false},
  { field: 'team', headerName: 'Team', flex: 1, headerClassName: 'super-app-theme--header', headerAlign: 'center', align: 'center', isDateTime: false},
  { field: 'points', headerName: 'Points', flex: 1, headerClassName: 'super-app-theme--header', headerAlign: 'center', align: 'center', isDateTime: false},
  { field: 'lastCatch', headerName: 'Time of Last Catch', flex: 1, headerClassName: 'super-app-theme--header', headerAlign: 'center', align: 'center', isDateTime: true},
];

export const catchAndReleaseMobileColumns = [
  { field: 'place', headerName: 'Place', width: 150, headerClassName: 'super-app-theme--header', headerAlign: 'center', align: 'center'},
  { field: 'team', headerName: 'Team', width: 150, headerClassName: 'super-app-theme--header', headerAlign: 'center', align: 'center'},
  { field: 'points', headerName: 'Points', width: 150, headerClassName: 'super-app-theme--header', headerAlign: 'center', align: 'center'},
  { field: 'lastCatch', headerName: 'Time of Last Catch', width: 300, headerClassName: 'super-app-theme--header', headerAlign: 'center', align: 'center', isDateTime: true},
];

export const weightOnlyDesktopColumns = [
  { field: 'place', headerName: 'Place', flex: 1, headerClassName: 'super-app-theme--header', headerAlign: 'center', align: 'center', isDateTime: false},
  { field: 'team', headerName: 'Team', flex: 1, headerClassName: 'super-app-theme--header', headerAlign: 'center', align: 'center', isDateTime: false},
  { field: 'weight', headerName: 'Points (Weight, lbs)', flex: 1, headerClassName: 'super-app-theme--header', headerAlign: 'center', align: 'center', isDateTime: false},
];

export const weightOnlyMobileColumns = [
  { field: 'place', headerName: 'Place', width: 150, headerClassName: 'super-app-theme--header', headerAlign: 'center', align: 'center'},
  { field: 'team', headerName: 'Team', width: 150, headerClassName: 'super-app-theme--header', headerAlign: 'center', align: 'center'},
  { field: 'weight', headerName: 'Points (Weight, lbs)', width: 150, headerClassName: 'super-app-theme--header', headerAlign: 'center', align: 'center', isDateTime: false},
];

export const weightAndLengthDesktopColumns = [
  { field: 'place', headerName: 'Place', flex: 1, headerClassName: 'super-app-theme--header', headerAlign: 'center', align: 'center', isDateTime: false},
  { field: 'team', headerName: 'Team', flex: 1, headerClassName: 'super-app-theme--header', headerAlign: 'center', align: 'center', isDateTime: false},
  { field: 'weight', headerName: 'Points (Weight, lbs)', flex: 1, headerClassName: 'super-app-theme--header', headerAlign: 'center', align: 'center', isDateTime: false},
  { field: 'length', headerName: 'Length (in)', flex: 1, headerClassName: 'super-app-theme--header', headerAlign: 'center', align: 'center', isDateTime: false},
];

export const weightAndLengthMobileColumns = [
  { field: 'place', headerName: 'Place', width: 150, headerClassName: 'super-app-theme--header', headerAlign: 'center', align: 'center'},
  { field: 'team', headerName: 'Team', width: 150, headerClassName: 'super-app-theme--header', headerAlign: 'center', align: 'center'},
  { field: 'weight', headerName: 'Points (Weight, lbs)', width: 150, headerClassName: 'super-app-theme--header', headerAlign: 'center', align: 'center', isDateTime: false},
  { field: 'length', headerName: 'Length (in)', width: 150, headerClassName: 'super-app-theme--header', headerAlign: 'center', align: 'center', isDateTime: false},
];

export const weightAndLengthAndGirthDesktopColumns = [
  { field: 'place', headerName: 'Place', flex: 1, headerClassName: 'super-app-theme--header', headerAlign: 'center', align: 'center', isDateTime: false},
  { field: 'team', headerName: 'Team', flex: 1, headerClassName: 'super-app-theme--header', headerAlign: 'center', align: 'center', isDateTime: false},
  { field: 'weight', headerName: 'Points (Weight, lbs)', flex: 1, headerClassName: 'super-app-theme--header', headerAlign: 'center', align: 'center', isDateTime: false},
  { field: 'length', headerName: 'Length (in)', flex: 1, headerClassName: 'super-app-theme--header', headerAlign: 'center', align: 'center', isDateTime: false},
  { field: 'girth', headerName: 'Girth (in)', flex: 1, headerClassName: 'super-app-theme--header', headerAlign: 'center', align: 'center', isDateTime: false},
];

export const weightAndLengthAndGirthMobileColumns = [
  { field: 'place', headerName: 'Place', width: 150, headerClassName: 'super-app-theme--header', headerAlign: 'center', align: 'center'},
  { field: 'team', headerName: 'Team', width: 150, headerClassName: 'super-app-theme--header', headerAlign: 'center', align: 'center'},
  { field: 'weight', headerName: 'Points (Weight, lbs)', width: 150, headerClassName: 'super-app-theme--header', headerAlign: 'center', align: 'center', isDateTime: false},
  { field: 'length', headerName: 'Length (in)', width: 150, headerClassName: 'super-app-theme--header', headerAlign: 'center', align: 'center', isDateTime: false},
  { field: 'girth', headerName: 'Girth (in)', width: 150, headerClassName: 'super-app-theme--header', headerAlign: 'center', align: 'center', isDateTime: false},
];

export const lengthOnlyDesktopColumns = [
  { field: 'place', headerName: 'Place', flex: 1, headerClassName: 'super-app-theme--header', headerAlign: 'center', align: 'center', isDateTime: false},
  { field: 'team', headerName: 'Team', flex: 1, headerClassName: 'super-app-theme--header', headerAlign: 'center', align: 'center', isDateTime: false},
  { field: 'length', headerName: 'Length (in)', flex: 1, headerClassName: 'super-app-theme--header', headerAlign: 'center', align: 'center', isDateTime: false},
];

export const lengthOnlyMobileColumns = [
  { field: 'place', headerName: 'Place', width: 150, headerClassName: 'super-app-theme--header', headerAlign: 'center', align: 'center'},
  { field: 'team', headerName: 'Team', width: 150, headerClassName: 'super-app-theme--header', headerAlign: 'center', align: 'center'},
  { field: 'length', headerName: 'Length (in)', width: 150, headerClassName: 'super-app-theme--header', headerAlign: 'center', align: 'center', isDateTime: false},
];

export const lengthAndWeightDesktopColumns = [
  { field: 'place', headerName: 'Place', flex: 1, headerClassName: 'super-app-theme--header', headerAlign: 'center', align: 'center', isDateTime: false},
  { field: 'team', headerName: 'Team', flex: 1, headerClassName: 'super-app-theme--header', headerAlign: 'center', align: 'center', isDateTime: false},
  { field: 'length', headerName: 'Length (in)', flex: 1, headerClassName: 'super-app-theme--header', headerAlign: 'center', align: 'center', isDateTime: false},
  { field: 'weight', headerName: 'Points (Weight, lbs)', flex: 1, headerClassName: 'super-app-theme--header', headerAlign: 'center', align: 'center', isDateTime: false},
];

export const lengthAndWeightMobileColumns = [
  { field: 'place', headerName: 'Place', width: 150, headerClassName: 'super-app-theme--header', headerAlign: 'center', align: 'center'},
  { field: 'team', headerName: 'Team', width: 150, headerClassName: 'super-app-theme--header', headerAlign: 'center', align: 'center'},
  { field: 'length', headerName: 'Length (in)', width: 150, headerClassName: 'super-app-theme--header', headerAlign: 'center', align: 'center', isDateTime: false},
  { field: 'weight', headerName: 'Points (Weight, lbs)', width: 150, headerClassName: 'super-app-theme--header', headerAlign: 'center', align: 'center', isDateTime: false},
];

export const lengthAndGirthDesktopColumns = [
  { field: 'place', headerName: 'Place', flex: 1, headerClassName: 'super-app-theme--header', headerAlign: 'center', align: 'center', isDateTime: false},
  { field: 'team', headerName: 'Team', flex: 1, headerClassName: 'super-app-theme--header', headerAlign: 'center', align: 'center', isDateTime: false},
  { field: 'length', headerName: 'Length (in)', flex: 1, headerClassName: 'super-app-theme--header', headerAlign: 'center', align: 'center', isDateTime: false},
  { field: 'girth', headerName: 'Girth (in)', flex: 1, headerClassName: 'super-app-theme--header', headerAlign: 'center', align: 'center', isDateTime: false},
];

export const lengthAndGirthMobileColumns = [
  { field: 'place', headerName: 'Place', width: 150, headerClassName: 'super-app-theme--header', headerAlign: 'center', align: 'center'},
  { field: 'team', headerName: 'Team', width: 150, headerClassName: 'super-app-theme--header', headerAlign: 'center', align: 'center'},
  { field: 'length', headerName: 'Length (in)', width: 150, headerClassName: 'super-app-theme--header', headerAlign: 'center', align: 'center', isDateTime: false},
  { field: 'girth', headerName: 'Girth (in)', width: 150, headerClassName: 'super-app-theme--header', headerAlign: 'center', align: 'center', isDateTime: false},
];

