import React, { useEffect, useState } from "react";
import { createBrowserRouter, RouterProvider, useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AnimatePresence } from "framer-motion";
import { appRoutes } from './routes/routes.js';
import './App.css';

function App() {
  const router = createBrowserRouter(appRoutes);

  return (
    <>
      <AnimatePresence>
        <RouterProvider router={router} />
        <ToastContainer />
      </AnimatePresence>
    </>
  );
}

export default App;
