import React from 'react';
import AnimatedPage from './AnimatedPage';

const ProfilePage = (props) => {
  return (
    <AnimatedPage>
      <div>Profile Page</div>
    </AnimatedPage>
  );
};

export default ProfilePage;

