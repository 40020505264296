import { Outlet } from 'react-router-dom';

import EventNavigation from './EventNavigation';

function EventLayout(props) {

  return(
    <>
      <EventNavigation {...props}/>
      <Outlet/>
    </>
  );
};

export default EventLayout;

