import { AppBar, Toolbar, Typography } from "@mui/material";
import { colorConfig, sizeConfig } from "../config/config";
import AnimatedPage from "../pages/AnimatedPage";

const Topbar = ({ sidebarExpanded, pageTitle }) => {
  return (
    <AnimatedPage>
      <AppBar
        position="fixed"
        sx={{
          width: `calc(100% - ${sidebarExpanded ? sizeConfig.sidebar.width : "70px"})`,
          ml: sizeConfig.sidebar.width,
          boxShadow: "unset",
          backgroundColor: colorConfig.topbar.bg,
          color: colorConfig.topbar.color,
        }}
      >
        <Toolbar sx={{ justifyContent: "center" }}>
          <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#304264' }}>
            {pageTitle}
          </Typography>
        </Toolbar>
      </AppBar>
    </AnimatedPage>
  );
};

export default Topbar;

