import React, { useEffect, useState } from 'react';
import { Avatar, Drawer, List, Stack, Toolbar, IconButton, ListItemButton, ListItemIcon, CircularProgress } from "@mui/material";
import { signOut } from 'firebase/auth';
import { useNavigate, useLocation } from 'react-router-dom';
import { auth } from "../firebase";
import { toast } from 'react-toastify';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { colorConfig, sizeConfig } from "../config/config";
import { appRoutes } from "../routes/routes";
import sidebarLogo from '../images/typescript-logo-240.png';
import LogoutIcon from '@mui/icons-material/Logout';
import { useMediaQuery } from '@mui/material';

const Sidebar = ({ sidebarExpanded, setSidebarExpanded }) => {
  const [sidebarInitialized, setSidebarInitialized] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = useMediaQuery('(max-width: 600px)'); // Updated mobile breakpoint

  // Retrieve username from localStorage if available
  const storedUsername = window.localStorage.getItem('username'); // Retrieve the plain string

  // Handle sidebar state initialization
  useEffect(() => {
    if (!isMobile) {
      const storedSidebarState = localStorage.getItem("sidebarExpanded");
      if (storedSidebarState !== null) {
        setSidebarExpanded(JSON.parse(storedSidebarState));
      }
    } else {
      // On mobile, set sidebarExpanded to false and disable toggling
      setSidebarExpanded(false);
    }
    setSidebarInitialized(true);
  }, [setSidebarExpanded, isMobile]);

  // Handle user logout
  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        window.localStorage.setItem('user', null);
        window.localStorage.setItem('username', null);
        toast.success('Logout successful! Redirecting...', { autoClose: 2000 });
        setTimeout(() => {
          navigate('/');
        }, 2000);
      })
      .catch((error) => {
        toast.error("There was an error while attempting to log you out. Please contact the site administrator.");
      });
  };

  if (!sidebarInitialized) return <CircularProgress />;

  return (
    <Drawer
      variant={isMobile ? "permanent" : "permanent"} // Keep permanent on mobile and desktop
      open={true} // Always open
      sx={{
        width: isMobile ? "70px" : sidebarExpanded ? sizeConfig.sidebar.width : "70px", // Static width on mobile
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: isMobile ? "70px" : sidebarExpanded ? sizeConfig.sidebar.width : "70px",
          boxSizing: "border-box",
          borderRight: "0px",
          backgroundColor: colorConfig.sidebar.bg,
          color: colorConfig.sidebar.color,
          transition: "width 0.3s ease-in-out",
        }
      }}
    >
      <List disablePadding>
        <Toolbar sx={{ justifyContent: isMobile ? 'center' : sidebarExpanded ? 'space-between' : 'center', padding: "16px" }}>
          {!isMobile && !sidebarExpanded ? (
            <IconButton onClick={() => setSidebarExpanded(true)} style={{ color: 'white' }}>
              <MenuIcon />
            </IconButton>
          ) : (
            <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ width: "100%" }}>
              <Avatar src={sidebarLogo} />
              {!isMobile && (
                <IconButton onClick={() => setSidebarExpanded(false)} style={{ color: 'white' }}>
                  <CloseIcon />
                </IconButton>
              )}
            </Stack>
          )}
        </Toolbar>

        {/* Render menu items */}
        {appRoutes.map((route, index) => (
          route.sidebarProps ? (
            <ListItemButton
              key={index}
              onClick={() => {
                const pathWithUsername = storedUsername ? route.path.replace(':username', storedUsername) : route.path;
                navigate(pathWithUsername);
              }}
              sx={{
                "&:hover": { backgroundColor: colorConfig.sidebar.hoverBg },
                paddingY: "12px",
                paddingX: sidebarExpanded ? "24px" : "12px",
                justifyContent: sidebarExpanded || isMobile ? "initial" : "center",
                backgroundColor: location.pathname.includes(route.path) ? colorConfig.sidebar.activeBg : "inherit",
              }}
            >
              <ListItemIcon sx={{ color: colorConfig.sidebar.color, justifyContent: sidebarExpanded ? 'left' : 'center' }}>
                {route.sidebarProps.icon}
              </ListItemIcon>
              {(sidebarExpanded) && route.sidebarProps.displayText}
            </ListItemButton>
          ) : null
        ))}

        {/* Add logout button */}
        <ListItemButton
          key="logout"
          onClick={handleLogout}
          sx={{
            "&:hover": { backgroundColor: colorConfig.sidebar.hoverBg },
            paddingY: "12px",
            paddingX: sidebarExpanded ? "24px" : "12px",
            justifyContent: sidebarExpanded || isMobile ? "initial" : "center",
          }}
        >
          <ListItemIcon sx={{ color: colorConfig.sidebar.color, justifyContent: sidebarExpanded ? 'left' : 'center' }}>
            <LogoutIcon />
          </ListItemIcon>
          {(sidebarExpanded) && 'Logout'}
        </ListItemButton>
      </List>
    </Drawer>
  );
};

export default Sidebar;

