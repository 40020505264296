import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import AnimatedPage from './AnimatedPage';
import Box from '@mui/material/Box';
import { Select, MenuItem } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';

import ToggleSliderButton from '../components/buttons/ToggleSliderButton';
import Carousel from '../components/tables/Carousel';
import LeaderboardResultTable from '../components/tables/LeaderboardResultTable';
import Footer from '../components/footer/Footer';
import { 
  catchAndReleaseDesktopColumns, 
  catchAndReleaseMobileColumns,
  weightOnlyDesktopColumns,
  weightOnlyMobileColumns,
  weightAndLengthDesktopColumns,
  weightAndLengthMobileColumns,
  weightAndLengthAndGirthDesktopColumns,
  weightAndLengthAndGirthMobileColumns,
  lengthOnlyDesktopColumns,
  lengthOnlyMobileColumns,
  lengthAndWeightDesktopColumns,
  lengthAndWeightMobileColumns,
  lengthAndGirthDesktopColumns,
  lengthAndGirthMobileColumns,
} from '../config/config';

import './BasePage.css';

dayjs.extend(advancedFormat);

function LeaderboardPage() {

  const { username, eventId } = useParams(); 
  const [eventSettings, setEventSettings] = useState(null);
  const [isEventSettingsLoading, setIsEventSettingsLoading] = useState(true);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const [timestamp, setTimestamp] = useState('');
  const [hasLoaded, setHasLoaded] = useState(false);
  const [tournamentHasStarted, setTournamentHasStarted] = useState(false);
  const [resultArray, setResultArray] = useState([]);
  const [isPreliminaryResults, setIsPreliminaryResults] = useState(true);
  
  // View state
  const viewList = ["List", "Select", "Slideshow"];
  const [viewAlignment, setViewAlignment] = useState('List');
  const [selectedResult, setSelectedResult] = useState([]);
  const [hasSelectedResult, setHasSelectedResult] = useState(false);

  useEffect(() => {
    const apiUrl = process.env.REACT_APP_NODE_ENV === 'production'
      ? process.env.REACT_APP_SERVER_URL_PRODUCTION
      : process.env.REACT_APP_SERVER_URL_STAGING;

    const fetchEventSettings = async () => {
      try {
        const response = await fetch(`${apiUrl}/api/admin_get_event_settings`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ username, eventId }),
        });
        const data = await response.json();
        if (response.ok && data.success) {
          setEventSettings(data.settings); 
        } else {
          console.error('Error fetching event settings:', data.message);
        }
      } catch (error) {
        console.error('Error fetching event settings:', error);
      } finally {
        console.log("Finally events!")
        setIsEventSettingsLoading(false);
      }
    };
    fetchEventSettings();
  }, [username, eventId]);

  // Fetch config and data after eventSettings has been loaded
  useEffect(() => {
    if (eventSettings) {
      fetchConfigAndData(); // Load config and fetch data
    }
  }, [eventSettings]);

  const fetchConfigAndData = async () => {
    try {
      // Display preliminary results disclaimer if tournament not over yet
      let now = dayjs().valueOf();
      setIsPreliminaryResults(parseInt(eventSettings.eventEndDateTimeGMT) > now);
      setTimestamp(generateTimestamp());

      // Build queries
      const queries = eventSettings.catchRows.map((element) => {

        // Assign url
        let url;
        let desktopColumns;
        let mobileColumns;
        if (element.scoring === "Catch & Release (Tiebreaker: Earliest Time)") {
          url = "get_event_leaderboard_for_catch_and_release_species";
          desktopColumns = catchAndReleaseDesktopColumns;
          mobileColumns = catchAndReleaseMobileColumns;
        } else if (element.scoring === "Weight Only (Tiebreaker: None)") {
          url = "get_event_leaderboard_for_weight_only_species";
          desktopColumns = weightOnlyDesktopColumns;
          mobileColumns = weightOnlyMobileColumns;
        } else if (element.scoring === "Weight (Tiebreaker: Length)") {
          url = "get_event_leaderboard_for_weight_and_length_species";
          desktopColumns = weightAndLengthDesktopColumns;
          mobileColumns = weightAndLengthMobileColumns;
        } else if (element.scoring === "Weight (Tiebreaker: Length, Girth)") {
          url = "get_event_leaderboard_for_weight_and_length_and_girth_species";
          desktopColumns = weightAndLengthAndGirthDesktopColumns;
          mobileColumns = weightAndLengthAndGirthMobileColumns;
        } else if (element.scoring === "Length Only (Tiebreaker: None)") {
          url = "get_event_leaderboard_for_length_only_species";
          desktopColumns = lengthOnlyDesktopColumns;
          mobileColumns = lengthOnlyMobileColumns;
        } else if (element.scoring === "Length (Tiebreaker: Weight)") {
          url = "get_event_leaderboard_for_length_and_weight_species";
          desktopColumns = lengthAndWeightDesktopColumns;
          mobileColumns = lengthAndWeightMobileColumns;
        } else if (element.scoring === "Length (Tiebreaker: Girth)") {
          url = "get_event_leaderboard_for_length_and_girth_species";
          desktopColumns = lengthAndGirthDesktopColumns;
          mobileColumns = lengthAndGirthMobileColumns;
        }
        
        return {
          title: element.species,
          subtitle: "",
          species: element.species,
          url: url,
          desktopColumns: desktopColumns,
          mobileColumns: mobileColumns,
        };
      });

      await confirmTournamentStarted();
      await fetchData(queries, setResultArray);
      setHasLoaded(true);

      setViewAlignment("List");
      setSelectedResult([]);
      setHasSelectedResult(false);
      
    } catch (error) {
      console.error('Error loading config or fetching data:', error);
    }
  };

  const fetchData = async (queries, setResults) => {
    try {
      const apiUrl = process.env.REACT_APP_NODE_ENV === "production"
        ? process.env.REACT_APP_SERVER_URL_PRODUCTION
        : process.env.REACT_APP_SERVER_URL_STAGING;

      // Fetch from server and set result state
      const res = await Promise.all(queries.map((query) => {
        return fetch(`${apiUrl}/api/${query.url}`, {
          method: 'POST',
          headers: {'Content-Type': 'application/json'},
          body: JSON.stringify({ 
            username: username, 
            eventId: eventId,
            species: query.species,
            isReport: false,
            numPlaces: eventSettings.numPlaces || 3,
          }),
        }).then(r => r.json()).then((result) => {
          let tempRows = [];
          Object.keys(result).forEach((catchKey, i) => {
            let tempObject = { ...result[catchKey], id: i, catchId: catchKey };
            tempRows.push(tempObject);
          });
          return {
            title: query.title,
            subtitle: query.subtitle,
            numPlaces: eventSettings.numPlaces,
            rows: tempRows,
            desktopColumns: query.desktopColumns,
            mobileColumns: query.mobileColumns
          };
        });
      }));

      setResults(res);

    } catch (error) {
      console.error('Error fetching data: ', error);
    }
  };

  const confirmTournamentStarted = async () => {
    const fetchTotalCatches = async () => {
      const apiUrl = process.env.REACT_APP_NODE_ENV === 'production'
        ? process.env.REACT_APP_SERVER_URL_PRODUCTION
        : process.env.REACT_APP_SERVER_URL_STAGING;

      try {
        const response = await fetch(`${apiUrl}/api/get_total_num_catches`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ username, eventId }),
        });
        const data = await response.json();
        if (data.success) {
          if (data.total > 0) {
            setTournamentHasStarted(true);
          } else {
            setTournamentHasStarted(false);
          }
        } else {
          console.error('Error fetching total catches:', data.message);
        }
      } catch (error) {
        console.error('Error fetching total catches:', error);
      }
    };

    await fetchTotalCatches();
  };

  const generateTimestamp = () => {
    const now = dayjs();
    const timeString = now.format('hh:mm A');
    const dateString = now.format('DD MMMM YYYY');
    return `Preliminary leaderboard as of: ${timeString} on ${dateString}.`;
  };

  const handleSelectResult = (e) => {
    let result = resultArray.filter(item => item.title === e.target.value);
    setSelectedResult(result);
    setHasSelectedResult(true);
  };

  return (
    <AnimatedPage>
      <main>
        {eventSettings && (
          <>
            {/* BANNER */}
            <section style={{ backgroundColor: eventSettings.backgroundColor }} className="section-banner">
              <h1 style={{ color: eventSettings.headlineColor }}>Leaderboard</h1>
            </section>

            <section className="section-leaderboard">
              <div style={{ marginTop: '0px', paddingTop: '0px' }}>
                <ToggleSliderButton choice={viewAlignment} choiceList={viewList} alignment={viewAlignment} setAlignment={setViewAlignment}/>
              </div>
            </section>

            {/* Leaderboard Views */}
            <section className="section-view">
              <Box sx={{ width: '90%', typography: 'body1' }}>
                {/* Preliminary results disclaimer message */}
                {isPreliminaryResults && (
                  <div>
                    <br/>
                    <h3 className="timestamp-text" style={{ color: "black" }}>
                      <em>{timestamp}</em>
                    </h3>
                    <br/>
                  </div>
                )}

                {/* Holdover message if there are no catches yet */}
                {!tournamentHasStarted && (
                  <div>
                    <br />
                    <h2 style={{ color: "black"}}>
                      The tournament will begin soon!
                    </h2>
                  </div>
                )}

                {/* List view */}
                {viewAlignment === "List" && tournamentHasStarted && (
                  !hasLoaded ? (
                    <div>
                      <h1>Loading...</h1>
                    </div>
                  ) : (
                    <div>
                      <br/>
                      {resultArray.map(result => {
                        if (result.rows.length > 0) {
                          return (
                            <LeaderboardResultTable
                              key={result.title}
                              style={{ width: '100%' }}
                              title={result.title}
                              subtitle={result.subtitle}
                              numPlaces={result.numPlaces}
                              rows={result.rows}
                              columns={matches ? result.desktopColumns : result.mobileColumns}
                              scroll={matches ? null : "scroll"}
                              density="compact"
                              eventSettings={eventSettings}  // Pass eventSettings here
                            />
                          );
                        }
                        return null;
                      })}
                    </div>
                  )
                )}

                {/* Slideshow View */}
                {viewAlignment === "Slideshow" && tournamentHasStarted && (
                  !hasLoaded ? (
                    <div>
                      <h1>Loading...</h1>
                    </div>
                  ) : (
                    <div>
                      <br/>
                      <Carousel eventSettings={eventSettings} results={resultArray} />
                    </div>
                  )
                )}

                {/* Select View */}
                {viewAlignment === "Select" && tournamentHasStarted && (
                  !hasLoaded ? (
                    <div>
                      <h1>Loading...</h1>
                    </div>
                  ) : (
                    <div>
                      <div className="select-div">
                        <br/>
                        {eventSettings.catchRows && (
                          <Select
                            labelId="select-category"
                            id="select-category"
                            value={selectedResult[0]?.title || ''}
                            onChange={handleSelectResult}
                          >
                            {eventSettings.catchRows.map((element) => (
                              <MenuItem key={element.species} value={element.species}>{element.species}</MenuItem>
                            ))}
                          </Select>
                        )}
                      </div>

                      {hasSelectedResult ? (
                        <div>
                          {selectedResult.map(result => (
                            result.rows.length > 0 ? (
                              <LeaderboardResultTable
                                key={result.title}
                                style={{ width: '100%' }}
                                title={result.title}
                                subtitle={result.subtitle}
                                numPlaces={result.numPlaces}
                                rows={result.rows}
                                columns={matches ? result.desktopColumns : result.mobileColumns}
                                scroll={matches ? null : "scroll"}
                                density="compact"
                                eventSettings={eventSettings}  // Pass eventSettings here
                              />
                            ) : (
                              <h1 key={result.title}>No results yet.</h1>
                            )
                          ))}
                        </div>
                      ) : (
                        <h1 style={{ color: "black" }}>Please select a category</h1>
                      )}
                    </div>
                  )
                )}
              </Box>
            </section>

            <Footer />
          </>
        )}
      </main>
    </AnimatedPage>
  );
}

export default LeaderboardPage;

