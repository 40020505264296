import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import dayjs from 'dayjs';

import AddTeamModal from '../modals/AddTeamModal';
import EditTeamModal from '../modals/EditTeamModal';
import DeleteTeamModal from '../modals/DeleteTeamModal';

import AddCatchModal from '../modals/AddCatchModal';
import EditCatchModal from '../modals/EditCatchModal';
import DeleteCatchModal from '../modals/DeleteCatchModal';

import AddAnnouncementModal from '../modals/AddAnnouncementModal';
import EditAnnouncementModal from '../modals/EditAnnouncementModal';
import DeleteAnnouncementModal from '../modals/DeleteAnnouncementModal';

import AdminToolbar from '../toolbars/AdminToolbar';

import defaultNoImage from '../../images/defaultNoImage.png';
import { teamsConfig, catchesConfig, announcementsConfig } from "../../config/config";

function CrudTable(props) {

  const { username, eventId } = useParams(); // Get username and eventId from the URL

  // STATE - STYLING
  const [buttonLabel, setButtonLabel] = useState();
  const [style, setStyle] = useState();
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [tableProperties, setTableProperties] = useState([]);
  const [scroll, setScroll] = useState();
  const [initialState, setInitialState] = useState();
  const [pageSizeOptions, setPageSizeOptions] = useState();
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({});

  // STATE - DATA
  const [editInfo, setEditInfo] = useState();
  const [isEditModalOpen, setIsEditModalOpen] = useState();
  const [deleteInfo, setDeleteInfo] = useState();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState();

  // INITIALIZE
  useEffect(() => {
    console.log('Here are the crud table rows:', props.rows)
    fetchConfigAndData(); // Load config and fetch data
  }, []);  // add tabName as a dependency to re-fetch when the tab changes

  const fetchConfigAndData = async () => {

    try {

      // Parse columns
      let rawColumns;
      if (props.tableType === "Teams") {
        rawColumns = teamsConfig;
      } else if (props.tableType === "Catches") {
        rawColumns = catchesConfig;
      } else if (props.tableType === "Announcements") {
        rawColumns = announcementsConfig;
      }

      // Step 1. Start with default columns
      const updatedColumnList = rawColumns.map(columnObject => {
        const updatedColumn = { ...columnObject };

        // Set visibility
        setColumnVisibilityModel(prev => ({
          ...prev,
          [columnObject.field]: columnObject.isVisible
        }));

        // Apply value formatters
        if (columnObject.isImage) {
          updatedColumn.renderCell = (params) => {
            const imageUrl = params.value || defaultNoImage; // Fallback to defaultNoImage if no image is available
        
            return (
              <img
                src={imageUrl}
                alt="Thumbnail"
                style={{ width: '50px', height: '50px', objectFit: 'cover' }} // Thumbnail size
                onError={(e) => {
                  // In case there's an error loading the image, fall back to the default image
                  e.target.src = defaultNoImage;
                }}
              />
            );
          };
        }

        if (columnObject.isDateTime) {
          updatedColumn.valueFormatter = (value) => {
            return dayjs(value).format('hh:mm A, MMM Do YYYY'); 
          };
        }

        if (columnObject.isCurrency) {
          updatedColumn.valueFormatter = (value) => {
            if (value == null) return ''; // Check if value is null or undefined
            return new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD', // You can replace 'USD' with the desired currency code
            }).format(value);
          };
        }

        if (columnObject.isAddOnCharge) {
          updatedColumn.valueFormatter = (value) => {
            if (value == null) return ''; // Check if value is null or undefined
            return value.quantityPurchased;
          };
        }
        
        if (columnObject.isAddOnCharge && columnObject.isCurrency) {
          updatedColumn.valueFormatter = (value) => {
            if (value == null) return ''; // Check if value is null or undefined
            return new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD', // You can replace 'USD' with the desired currency code
            }).format(value.price);
          };
        }

        return updatedColumn;
      });

      if (props.tableType === "Teams" && props.eventSettings?.customTeamFields?.length) {
        props.eventSettings.customTeamFields.forEach((field) => {
          updatedColumnList.push({
            field: field.field,
            headerName: field.field,
            type: field.type.toLowerCase(),
            isEditable: true,
            isVisible: true,
            headerClassName: "super-app-theme--header",
            headerAlign: "center",
            align: "center",
            valueFormatter: (params) => {
              if (field.type === 'Email') {
                return <a href={`mailto:${params.value}`}>{params.value}</a>;
              } else if (field.type === 'Phone') {
                return <a href={`tel:${params.value}`}>{params.value}</a>;
              }
              return params.value;
            },
          });
        });
      }
  
      // Step 3: Dynamically assign column width based on screen size for both default and custom columns
      const screenWidth = window.innerWidth;
      updatedColumnList.forEach(column => {
        if (screenWidth <= 750) { // Mobile
          column.width = column.width || 150; // Default to 150px if no width is set
          delete column.flex; // No flex on mobile
        } else if (screenWidth <= 1024) { // Tablet
          column.width = column.width || 200; // Default to 200px if no width is set
          delete column.flex; // No flex on tablet
        } else { // Desktop and larger
          column.flex = column.flex || 1; // Flex instead of fixed width
          delete column.width; // Remove width property on desktop
        }
      });
  
      // Step 4: Add action buttons
      updatedColumnList.push({
        field: 'actions',
        type: 'actions',
        headerName: 'Actions',
        headerClassName: 'super-app-theme--header',
        width: 100,
        cellClassName: 'actions',
        getActions: ({ id }) => [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={() => handleEdit(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={() => handleDelete(id)}
            color="inherit"
          />,
        ],
      });

      // Update state
      setButtonLabel(props.buttonLabel);
      setStyle(props.style);
      setTableProperties(props.tableProperties);
      setScroll(props.scroll);
      setInitialState(props.initialState);
      setRows(props.rows);
      setColumns(updatedColumnList);
      setPageSizeOptions(props.pageSizeOptions);

    } catch (error) {
      console.log('There was an error loading the data for the CRUD Table: ' + error);
    }

    setIsDeleteModalOpen(false);
    setIsEditModalOpen(false);
  };

  // HELPERS
  const handleOpenAddModal = () => {props.openAddModal()};    // Add
  const handleCloseAddModal = () => {props.closeAddModal()};

  const openEditModal = () => {setIsEditModalOpen(true)};    // Edit
  const closeEditModal = () => {
    setEditInfo();
    setIsEditModalOpen(false);
  }
  const handleEdit = async (id) => {
    const rowToEdit = props.rows.find((row) => row.announcementId === id || row.teamId === id || row.catchId === id);
    if (rowToEdit) {
      console.log("Selected row " + id + " to edit. The info is: ", rowToEdit);
      setEditInfo(rowToEdit);
      openEditModal();
    } else {
      console.error("Row with id " + id + " not found.");
    }
  };

  const openDeleteModal = () => {setIsDeleteModalOpen(true)};    // Delete
  const closeDeleteModal = () => {
    setDeleteInfo();
    setIsDeleteModalOpen(false);
  } 
  const handleDelete = async (id) => {
    const rowToDelete = props.rows.find((row) => row.announcementId === id || row.teamId === id || row.catchId === id);
    if (rowToDelete) {
      console.log("Selected row " + id + " to delete. The info is: ", rowToDelete);
      setDeleteInfo(rowToDelete);
      openDeleteModal();
    } else {
      console.error("Row with id " + id + " not found.");
    }
  };
  

  return (
    <div style={style}>

      {/* DELETE */}
      { (deleteInfo && props.tableType === "Teams") && 
        <DeleteTeamModal 
          eventSettings={props.eventSettings}
          username={username}
          eventId={eventId}
          tableType={props.tableType}
          tableProperties={tableProperties}
          deleteInfo={deleteInfo} 
          status={isDeleteModalOpen} 
          open={openDeleteModal} 
          close={closeDeleteModal} 
        />
      }
      { (deleteInfo && props.tableType === "Catches") && 
        <DeleteCatchModal 
          eventSettings={props.eventSettings}
          username={username}
          eventId={eventId}
          tableType={props.tableType}
          tableProperties={tableProperties}
          deleteInfo={deleteInfo} 
          status={isDeleteModalOpen} 
          open={openDeleteModal} 
          close={closeDeleteModal} 
        />
      }
      { (deleteInfo && props.tableType === "Announcements") &&  
        <DeleteAnnouncementModal 
          eventSettings={props.eventSettings}  
          username={username}
          eventId={eventId}
          tableType={props.tableType}
          tableProperties={tableProperties}
          deleteInfo={deleteInfo} 
          status={isDeleteModalOpen} 
          open={openDeleteModal} 
          close={closeDeleteModal} 
        />
      }

      {/* EDIT */}
      { (editInfo && props.tableType === "Teams") && 
        <EditTeamModal 
          eventSettings={props.eventSettings}
          username={username}
          eventId={eventId}
          tableType={props.tableType}
          tableProperties={tableProperties}
          editInfo={editInfo} 
          status={isEditModalOpen} 
          open={openEditModal} 
          close={closeEditModal} 
          startDate={props.startDate}
          endDate={props.endDate} 
        />
      }
      { (editInfo && props.tableType === "Catches") && 
        <EditCatchModal 
          eventSettings={props.eventSettings}
          username={username}
          eventId={eventId}
          tableType={props.tableType}
          tableProperties={tableProperties}
          editInfo={editInfo} 
          status={isEditModalOpen} 
          open={openEditModal} 
          close={closeEditModal} 
          startDate={props.startDate}
          endDate={props.endDate} 
        />
      }
      { (editInfo && props.tableType === "Announcements") && 
        <EditAnnouncementModal  
          eventSettings={props.eventSettings}
          username={username}
          eventId={eventId}
          tableType={props.tableType}
          tableProperties={tableProperties}
          editInfo={editInfo} 
          status={isEditModalOpen} 
          open={openEditModal} 
          close={closeEditModal} 
          startDate={props.startDate}
          endDate={props.endDate} 
        />
      }
   
      <div style={style}>

        {/* ADD */}
        { (props.tableType === "Teams") && 
          <AddTeamModal 
            eventSettings={props.eventSettings}
            username={username}
            eventId={eventId}
            isAdmin={true}
            tableStyle={props.tableStyle}
            today={props.today} 
            startDate={props.startDate}
            endDate={props.endDate} 
            status={props.addStatus} 
            open={props.openAddModal} 
            close={props.closeAddModal}  
          />
        }
        { (props.tableType === "Catches") && 
          <AddCatchModal 
            eventSettings={props.eventSettings}
            username={username}
            eventId={eventId}
            isAdmin={true}
            tableStyle={props.tableStyle}
            today={props.today} 
            startDate={props.startDate}
            endDate={props.endDate} 
            status={props.addStatus} 
            open={props.openAddModal} 
            close={props.closeAddModal}  
          />
        }
        { (props.tableType === "Announcements") && 
          <AddAnnouncementModal 
            eventSettings={props.eventSettings}
            username={username}
            eventId={eventId}
            isAdmin={true}
            tableStyle={props.tableStyle}
            today={props.today} 
            startDate={props.startDate}
            endDate={props.endDate} 
            status={props.addStatus} 
            open={props.openAddModal} 
            close={props.closeAddModal}  
          />
        }

        {/* TABLE */}
        <DataGrid
          VerticalContentAlignment="center"
          rows={rows || []}
          columns={columns || []}
          columnVisibilityModel={columnVisibilityModel}
          onColumnVisibilityModelChange={(newModel) => setColumnVisibilityModel(newModel)}
          getRowId={row => {
            if (props.tableType === "Teams") return row.teamId;
            if (props.tableType === "Catches") return row.catchId;
            if (props.tableType === "Announcements") return row.announcementId;
            return row.id; // As a fallback, but ideally every row should have a unique identifier
          }}
          sx={{
            overflowX: scroll,
            '.MuiDataGrid-toolbarContainer .MuiButton-root': {
                backgroundColor: 'white', // Button background color
                color: "black",
                '&:hover': {
                  backgroundColor: '#d3d3d3', // Hover background color
                },
              },
              // Other customizations
              '.MuiDataGrid-toolbarContainer': {
                color: "black",
              },
            '.MuiDataGrid-row.Mui-odd': {
              backgroundColor: 'rgba(234, 234, 234)',
            },
            '.MuiDataGrid-columnHeaderTitleContainer': {
              fontSize: '16px',
              backgroundColor: props.eventSettings.backgroundColor,
              color: props.eventSettings.headlineColor,
              ".MuiSvgIcon-root": {
                color: props.eventSettings.headlineColor,
              }
            },
            '& .super-app-theme--header': {
              backgroundColor: props.eventSettings.backgroundColor,
              color: 'white',
              fontSize: '16px',
            },
            '& .MuiDataGrid-cell': {
              justifyContent: 'center',
              textAlign: 'center',
              fontSize: '16px',
              color: "black",
            },
          }}
          initialState={initialState}
          pageSizeOptions={pageSizeOptions}
          getRowClassName={(params) =>
            params.indexRelativeToCurrentPage % 2 === 0 ? 'Mui-even' : 'Mui-odd'
          }
          slots={{ toolbar: AdminToolbar }}
          slotProps={{
            toolbar: { 
              handleOpenAddModal,
              handleCloseAddModal, 
              buttonLabel,
            },
          }}
        />
      </div>
    </div>
  );
}

export default CrudTable;

