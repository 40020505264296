import React, { useState, useEffect } from 'react';
import { InputLabel, Button, Dialog, DialogContent, DialogTitle, IconButton, Stack, TextField, Select, MenuItem, CircularProgress } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';

const EditTeamModal = (props) => {
  const [formData, setFormData] = useState({});
  const [teamNames, setTeamNames] = useState([]);
  const [originalTeamName, setOriginalTeamName] = useState(''); // Store original team name
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  useEffect(() => {
    fetchTeamNames();
    initializeFormData();
  }, [props.editInfo]);

  const fetchTeamNames = async () => {
    try {
      const apiUrl = process.env.REACT_APP_NODE_ENV === 'production'
        ? process.env.REACT_APP_SERVER_URL_PRODUCTION
        : process.env.REACT_APP_SERVER_URL_STAGING;

      const response = await fetch(`${apiUrl}/api/admin_get_event_team_names`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username: props.username, eventId: props.eventId })
      });

      if (response.ok) {
        const data = await response.json();
        setTeamNames(data.teamNames || []);
      } else {
        toast.error('Error fetching team names.');
      }
    } catch (error) {
      console.error('Error fetching team names:', error);
      toast.error('Error fetching team names.');
    }
  };

  const initializeFormData = () => {
    const initialData = {};
    props.eventSettings.defaultTeamFields.concat(props.eventSettings.customTeamFields).forEach(field => {
      initialData[field.field] = props.editInfo[field.field] || '';
    });

    setFormData(initialData);
    setOriginalTeamName(props.editInfo["Team name"]); // Store the original team name
    setIsLoading(false);
  };

  const handleClose = () => {
    setFormData({});
    setTeamNames([]);
    setIsSubmitting(false);
    setIsSubmitted(false);
    props.close();
  };

  const handleInputChange = (field, value) => {
    setFormData(prevData => ({
      ...prevData,
      [field]: value
    }));
  };

  const validateUserInput = () => {
    if (isLoading) {
      toast.warning("Please wait while the team data is loading.");
      return false;
    }

    let inputIsValid = true;

    // Validate default fields
    props.eventSettings.defaultTeamFields.forEach(field => {
      if (field.required && !formData[field.field]) {
        toast.error(`${field.field} is required.`);
        inputIsValid = false;
      }
    });

    // Validate custom fields
    props.eventSettings.customTeamFields.forEach(field => {
      if (field.required && !formData[field.field]) {
        toast.error(`${field.field} is required.`);
        inputIsValid = false;
      }
    });

    // Validate Email
    const emailField = props.eventSettings.defaultTeamFields.find(f => f.type === 'Email') || {};
    if (emailField && formData[emailField.field] && !validateEmail(formData[emailField.field])) {
      toast.error('Invalid email format.');
      inputIsValid = false;
    }

    // Validate Phone
    const phoneField = props.eventSettings.defaultTeamFields.find(f => f.type === 'Phone') || {};
    if (phoneField && formData[phoneField.field] && !validatePhone(formData[phoneField.field])) {
      toast.error('Invalid phone number format.');
      inputIsValid = false;
    }

    // Check for duplicate team names, excluding the original team name
    if (formData["Team name"] !== originalTeamName && teamNames.includes(formData["Team name"])) {
      toast.error('Team name already exists.');
      inputIsValid = false;
    }

    return inputIsValid;
  };

  const validateEmail = (email) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };

  const validatePhone = (phone) => {
    return phone.match(
      /^(\+?\d{1,2}\s?)?(\(?\d{3}\)?[\s.-]?)?\d{3}[\s.-]?\d{4}$/
    );
  };

  const handleEdit = async () => {
    if (validateUserInput()) {
      setIsSubmitting(true);
      try {
        let apiUrl = process.env.REACT_APP_NODE_ENV === "staging"
          ? process.env.REACT_APP_SERVER_URL_STAGING
          : process.env.REACT_APP_SERVER_URL_PRODUCTION;

        const updatedTeam = { ...formData, teamId: props.editInfo.teamId };

        const response = await fetch(`${apiUrl}/api/admin_edit_event_teams`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            username: props.username,
            eventId: props.eventId,
            updatedTeam,
          }),
        });
    
        if (response.ok) {
          toast.success('Team updated successfully!');
          setIsSubmitted(true);
          delayRefresh();
        } else {
          toast.error('Error updating team.');
          setIsSubmitting(false);
        }
      } catch (error) {
        console.error('Error updating team:', error);
        toast.error('Error updating team.');
        setIsSubmitting(false);
      }
    }
  };

  const delayRefresh = () => {
    setTimeout(() => {
      window.location.reload();
    }, 2000);
  };

  return (
    <Dialog open={props.status} onClose={handleClose} fullWidth maxWidth="sm">
      <form onSubmit={(e) => { e.preventDefault(); handleClose(); }}>
        <DialogTitle>Edit Team Information<IconButton onClick={handleClose} style={{float:'right'}}><CloseIcon color="primary"></CloseIcon></IconButton></DialogTitle>
        <DialogContent>
          <Stack spacing={2} margin={2}>

            {/* Team ID at the top */}
            <InputLabel><strong>Team ID:</strong> {props.editInfo.teamId}</InputLabel>

            {props.eventSettings.defaultTeamFields.concat(props.eventSettings.customTeamFields).map(field => (
              <TextField
                key={field.field}
                label={field.field}
                value={formData[field.field]}
                onChange={(e) => handleInputChange(field.field, e.target.value)}
                required={field.required}
                fullWidth
              />
            ))}

                        {/* Editable hasCheckedIn field */}
                        <Select
              key="Has checked in"
              label="Has checked in"
              value={formData['hasCheckedIn'] || false}
              onChange={(e) => handleInputChange('hasCheckedIn', e.target.value)}
              fullWidth
            >
              <MenuItem value={true}>Checked In</MenuItem>
              <MenuItem value={false}>Not Checked In</MenuItem>
            </Select>
          
            {/* Submit button */}
            {!isSubmitted ? (
              <Button
                color="primary"
                variant="contained"
                onClick={handleEdit}
                disabled={isSubmitting || isSubmitted}
                startIcon={isSubmitting ? <CircularProgress size={20} /> : null}
              >
                {isSubmitting ? "Submitting..." : "Submit"}
              </Button>
            ) : (
              <h3>Submitted!</h3>
            )}
          </Stack>
        </DialogContent>
      </form>
    </Dialog>
  );
};

export default EditTeamModal;

