import React, { useEffect, useState } from 'react';
import { FaAngleDoubleUp } from 'react-icons/fa';
import './BackToTopButton.css';

function BackToTopButton() {

  const [BackToTopButtonVisible, setBackToTopButtonVisible] = useState(false);

  const scrollUp = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setBackToTopButtonVisible(true);
      } else {
        setBackToTopButtonVisible(false);
      }
    };
    
    window.addEventListener("scroll", handleScroll);
    
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div>
      {BackToTopButtonVisible && (
        <a onClick={scrollUp}>
          <i>
            <FaAngleDoubleUp size={30} />
          </i>
        </a>
      )}
    </div>
  );
}

export default BackToTopButton;

