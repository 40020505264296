import React from 'react';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { ButtonBase } from '@mui/material';

const CreateEventCard = ({ cardWidth, cardHeight, open }) => {
  return (
    <ButtonBase
      onClick={() => {
        console.log("CreateEventCard clicked!"); // Verify the click event
        if (open) open(); // Call the open function if it exists
      }}
      sx={{
        width: cardWidth,
        height: cardHeight,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        border: '2px dashed #cccccc',
        borderRadius: '8px', // Optional: Rounded corners
        transition: '0.3s',
        '&:hover': {
          backgroundColor: '#f0f0f0',
          borderColor: '#aaaaaa',
        },
      }}
    >
      <h3 style={{ color: '#cccccc', marginBottom: '10px' }}>Create New Event</h3>
      <AddCircleIcon sx={{ fontSize: 80, color: '#cccccc' }} />
    </ButtonBase>
  );
};

export default CreateEventCard;
