import jsPDF from 'jspdf';
import 'jspdf-autotable';
import dayjs from 'dayjs';

const addPageNumbers = (doc) => {
  const pageCount = doc.internal.getNumberOfPages();
  for (let i = 1; i <= pageCount; i++) {
    doc.setPage(i);
    doc.setFontSize(10);
    doc.text(
      `${i} of ${pageCount}`,
      doc.internal.pageSize.getWidth() - 25,
      doc.internal.pageSize.getHeight() - 10
    );
  }
};

export const generateCatchesByTeamReport = (catchesData, tournamentName) => {
  const doc = new jsPDF('landscape');
  const currentDate = dayjs().format('MMMM D, YYYY h:mm A [CST]');
  const currentYear = new Date().getFullYear();

  // Group data by team
  const teamGroups = {};

  catchesData.forEach((catchItem) => {
    if (!teamGroups[catchItem.teamName]) {
      teamGroups[catchItem.teamName] = [];
    }
    teamGroups[catchItem.teamName].push(catchItem);
  });

  const teamNames = Object.keys(teamGroups);

  teamNames.forEach((team, index) => {
    const catches = teamGroups[team];

    // Sort catches by dateTime in descending order
    catches.sort((a, b) => new Date(b.dateTime) - new Date(a.dateTime));

    if (index > 0) doc.addPage();

    doc.setFontSize(16);
    doc.setFont('helvetica', 'bold');

    const totalCatches = catches.length;
    doc.text(
      `Catches for ${team} - ${tournamentName} ${currentYear} (${totalCatches} total)`,
      10,
      10
    );
    doc.text(`Report generated on ${currentDate}`, 10, 18);

    const tableColumn = [
      'No.',
      'Species',
      'Time of Catch',
      'Weight (lb)',
      'Length (in)',
      'Girth (in)',
    ];
    const tableRows = [];

    catches.forEach((catchItem, idx) => {
      tableRows.push([
        idx + 1, // Row number
        catchItem.species || 'N/A',
        dayjs(catchItem.dateTime).format('MMMM D, YYYY h:mm A') || 'N/A',
        catchItem.weight || 'N/A',
        catchItem.length || 'N/A',
        catchItem.girth || 'N/A',
      ]);
    });

    doc.autoTable({
      startY: 30,
      head: [tableColumn],
      body: tableRows,
      theme: 'striped',
      styles: {
        fontSize: 10,
        halign: 'center',
        valign: 'middle',
        overflow: 'linebreak',
      },
      headStyles: {
        fillColor: '#02133E',
        textColor: '#ffffff',
        halign: 'center',
      },
    });
  });

  addPageNumbers(doc);
  doc.save(`${tournamentName}_${currentYear} Catches (By Team).pdf`);
};

export const fetchAndGenerateCatchesByTeamReport = async (
  username,
  eventId,
  tournamentName
) => {
  console.log('Fetching and generating catches report by team...');

  const apiUrl =
    process.env.REACT_APP_NODE_ENV === 'production'
      ? process.env.REACT_APP_SERVER_URL_PRODUCTION
      : process.env.REACT_APP_SERVER_URL_STAGING;

  try {
    const [catchesResponse, teamsResponse] = await Promise.all([
      fetch(`${apiUrl}/api/admin_get_event_catches`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, eventId }),
      }),
      fetch(`${apiUrl}/api/admin_get_event_teams`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, eventId }),
      }),
    ]);

    if (!catchesResponse.ok || !teamsResponse.ok) {
      throw new Error('Error fetching data');
    }

    const catchesData = await catchesResponse.json();
    const teamsData = await teamsResponse.json();

    const catchesArray = Object.values(catchesData);

    generateCatchesByTeamReport(catchesArray, tournamentName);
  } catch (error) {
    console.error('Error fetching data for catches report:', error);
  }
};

export const generateCatchesBySpeciesReport = (catchesData, tournamentName) => {
  const doc = new jsPDF('landscape');
  const currentDate = dayjs().format('MMMM D, YYYY h:mm A [CST]');
  const currentYear = new Date().getFullYear();

  // Group data by species
  const speciesGroups = {};

  catchesData.forEach((catchItem) => {
    if (!speciesGroups[catchItem.species]) {
      speciesGroups[catchItem.species] = [];
    }
    speciesGroups[catchItem.species].push(catchItem);
  });

  const speciesNames = Object.keys(speciesGroups);

  speciesNames.forEach((species, index) => {
    const catches = speciesGroups[species];

    // Sort catches by dateTime in descending order
    catches.sort((a, b) => new Date(b.dateTime) - new Date(a.dateTime));

    if (index > 0) doc.addPage();

    doc.setFontSize(16);
    doc.setFont('helvetica', 'bold');
    doc.text(
      `Catches for ${species} - ${tournamentName} ${currentYear}`,
      10,
      10
    );
    doc.text(`Report generated on ${currentDate}`, 10, 18);

    const tableColumn = [
      'No.',
      'Species',
      'Team Name',
      'Time of Catch',
      'Weight (lb)',
      'Length (in)',
      'Girth (in)',
    ];
    const tableRows = [];

    catches.forEach((catchItem, idx) => {
      tableRows.push([
        idx + 1, // Row number
        catchItem.species || 'N/A',
        catchItem.teamName || 'N/A',
        dayjs(catchItem.dateTime).format('MMMM D, YYYY h:mm A') || 'N/A',
        catchItem.weight || 'N/A',
        catchItem.length || 'N/A',
        catchItem.girth || 'N/A',
      ]);
    });

    doc.autoTable({
      startY: 30,
      head: [tableColumn],
      body: tableRows,
      theme: 'striped',
      styles: {
        fontSize: 10,
        halign: 'center',
        valign: 'middle',
        overflow: 'linebreak',
      },
      headStyles: {
        fillColor: '#02133E',
        textColor: '#ffffff',
        halign: 'center',
      },
    });
  });

  addPageNumbers(doc);
  doc.save(`${tournamentName}_${currentYear} Catches (By Species).pdf`);
};

export const fetchAndGenerateCatchesBySpeciesReport = async (
  username,
  eventId,
  tournamentName
) => {
  console.log('Fetching and generating catches report by species...');

  const apiUrl =
    process.env.REACT_APP_NODE_ENV === 'production'
      ? process.env.REACT_APP_SERVER_URL_PRODUCTION
      : process.env.REACT_APP_SERVER_URL_STAGING;

  try {
    const response = await fetch(`${apiUrl}/api/admin_get_event_catches`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ username, eventId }),
    });

    if (!response.ok) {
      throw new Error(`Error fetching data: ${response.statusText}`);
    }

    const catchesData = await response.json();

    // Convert catchesData to an array
    const catchesArray = Object.values(catchesData);

    generateCatchesBySpeciesReport(catchesArray, tournamentName);
  } catch (error) {
    console.error('Error fetching catch data or generating PDF:', error);
  }
};
