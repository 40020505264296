import React, { useState, useEffect } from 'react';
import { InputLabel, Button, Dialog, DialogContent, DialogTitle, IconButton, Stack, TextField, CircularProgress } from "@mui/material";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import CloseIcon from "@mui/icons-material/Close";
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';

const EditCatchModal = (props) => {
  const [length, setLength] = useState();
  const [girth, setGirth] = useState();
  const [weight, setWeight] = useState();
  const [points, setPoints] = useState();
  const [dateTime, setDateTime] = useState();
  const [catchPhoto, setCatchPhoto] = useState();
  const [catchPhotoUrl, setCatchPhotoUrl] = useState();
  const [speciesConfig, setSpeciesConfig] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  useEffect(() => {
    // Set initial values from props
    setLength(props.editInfo.length);
    setGirth(props.editInfo.girth);
    setWeight(props.editInfo.weight);
    setPoints(props.editInfo.points);
    setDateTime(props.editInfo.dateTime);
    setCatchPhoto(null);
    setCatchPhotoUrl(props.editInfo.catchPhoto);

    // Fetch species configuration based on species in editInfo
    const speciesMatch = props.eventSettings.catchRows.find(
      (species) => species.species === props.editInfo.species
    );
    setSpeciesConfig(speciesMatch || {});
  }, [props]);

  const handleClose = () => {
    props.close();
    resetFields();
  };

  const resetFields = () => {
    setLength();
    setGirth();
    setWeight();
    setPoints();
    setDateTime();
    setCatchPhoto();
    setIsSubmitting(false);
    setIsSubmitted(false);
  };

  const calculatePoints = (newLength, newWeight) => {
    if (speciesConfig?.scoring === 'Catch & Release (Tiebreaker: Earliest Time)') {
      return speciesConfig.points; // Use predefined points for Catch & Release
    } else if (speciesConfig?.scoring.includes('Weight')) {
      // Use Math.floor to round down the weight to the nearest integer
      return Math.floor(newWeight || 0);
    } else if (speciesConfig?.scoring.includes('Length')) {
      // Use Math.floor to round down the length to the nearest integer
      return Math.floor(newLength || 0);
    }
    return 0;
  };

  // Update weight and recalculate points
  const handleWeightSelection = (event) => {
    const weightValue = parseFloat(event.target.value);
    setWeight(weightValue);
    const updatedPoints = calculatePoints(length, weightValue);
    setPoints(updatedPoints); // Recalculate points after updating weight
  };

  // Update length and recalculate points
  const handleLengthSelection = (event) => {
    const lengthValue = parseFloat(event.target.value);
    setLength(lengthValue);
    const updatedPoints = calculatePoints(lengthValue, weight);
    setPoints(updatedPoints); // Recalculate points after updating length
  };

  const handleGirthSelection = (event) => {
    const girthValue = parseFloat(event.target.value);
    setGirth(girthValue);
  };

  const handleDateTimeSelection = (event) => {
    setDateTime(event ? event.toISOString() : null);
  };

  const handleEdit = async () => {
    if (validateUserInput()) {
      setIsSubmitting(true);

      try {
        const apiUrl = process.env.REACT_APP_NODE_ENV === "staging"
          ? process.env.REACT_APP_SERVER_URL_STAGING
          : process.env.REACT_APP_SERVER_URL_PRODUCTION;

        const formData = {
          username: props.username,
          eventId: props.eventId,
          catchId: props.editInfo.catchId,
          species: speciesConfig.species, // Species is no longer editable
          speciesType: speciesConfig.scoring,
          dateTime: dateTime ? dayjs(dateTime).toISOString() : '',
          length,
          girth,
          weight,
          points,
          catchPhoto,
        };

        const response = await fetch(`${apiUrl}/api/admin_edit_event_catches`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formData),
        });

        if (response.ok) {
          toast.success('Catch successfully updated! Redirecting...');
          setIsSubmitted(true);
          delayRefresh();
        } else {
          toast.error('Error updating the catch.');
          setIsSubmitting(false);
        }
      } catch (error) {
        toast.error('There was an error updating the catch.');
        setIsSubmitting(false);
      }
    }
  };

  const validateUserInput = () => {
    let inputIsValid = true;

    if (!dateTime && speciesConfig?.scoring.includes('Time')) {
      toast.warning("Please enter a date and time for the catch.");
      inputIsValid = false;
    }
    if (speciesConfig?.scoring.includes('Length') && (!length || length <= 0)) {
      toast.warning("Please enter a valid length.");
      inputIsValid = false;
    }
    if (speciesConfig?.scoring.includes('Girth') && (!girth || girth <= 0)) {
      toast.warning("Please enter a valid girth.");
      inputIsValid = false;
    }
    if (speciesConfig?.scoring.includes('Weight') && (!weight || weight <= 0)) {
      toast.warning("Please enter a valid weight.");
      inputIsValid = false;
    }

    return inputIsValid;
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setCatchPhoto(file);
      setCatchPhotoUrl(URL.createObjectURL(file));
    }
  };

  const delayRefresh = () => {
    setTimeout(() => {
      window.location.reload();
    }, 2000);
  };

  return (
    <Dialog open={props.status} onClose={handleClose} fullWidth maxWidth="sm">
      <form onSubmit={(e) => e.preventDefault()}>
        <DialogTitle>Edit {props.year} Catch<IconButton onClick={handleClose} style={{ float: 'right' }}><CloseIcon /></IconButton></DialogTitle>
        <DialogContent>
          <Stack spacing={2} margin={2}>
            {/* Display the species as plain text */}
            <InputLabel><strong>Species: </strong>{speciesConfig?.species}</InputLabel>
            
            <InputLabel id="points-label"><strong>Points: </strong>{points}</InputLabel>
            <InputLabel><strong>Caught By: </strong>{props.editInfo.teamName}</InputLabel>

            {speciesConfig?.scoring.includes('Time') &&
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  id="edit-catch-date"
                  value={dayjs(dateTime)}
                  onChange={handleDateTimeSelection}
                  minDate={dayjs(Number(props.eventSettings.eventStartDateTimeGMT))}
                  maxDate={dayjs(Number(props.eventSettings.eventEndDateTimeGMT))}
                />
              </LocalizationProvider>
            }

            {speciesConfig?.scoring.includes('Length') &&
              <TextField
                type="number"
                label="Length (by 1/8 inch)"
                value={length || ''}
                onChange={handleLengthSelection}
                InputProps={{ inputProps: { step: 0.125, min: 0.125 } }}
              />
            }

            {speciesConfig?.scoring.includes('Girth') &&
              <TextField
                type="number"
                label="Girth (by 1/8 inch)"
                value={girth || ''}
                onChange={handleGirthSelection}
                InputProps={{ inputProps: { step: 0.125, min: 0.125 } }}
              />
            }

            {speciesConfig?.scoring.includes('Weight') &&
              <TextField
                type="number"
                label="Weight (by 1/10 lb)"
                value={weight || ''}
                onChange={handleWeightSelection}
                InputProps={{ inputProps: { step: 0.1, min: 0.1 } }}
              />
            }

            {speciesConfig?.scoring.includes('Photo') &&
              <div>
                <input
                  accept="image/*"
                  type="file"
                  id="edit-catch-photo"
                  style={{ display: 'none' }}
                  onChange={handleImageChange}
                />
                <label htmlFor="edit-catch-photo">
                  <Button variant="contained" component="span">Upload Photo*</Button>
                </label>
                {catchPhotoUrl &&
                  <div style={{ width: '100px', height: '100px', marginTop: '10px' }}>
                    <img src={catchPhotoUrl} alt="Catch preview" style={{ width: '100px', height: '100px', objectFit: 'cover' }} />
                    <Button onClick={() => setCatchPhotoUrl(null)}>Remove Photo</Button>
                  </div>
                }
              </div>
            }

            {!isSubmitted ? (
              <Button
                color="primary"
                variant="contained"
                onClick={handleEdit}
                disabled={isSubmitting || isSubmitted}
                startIcon={isSubmitting ? <CircularProgress size={20} /> : null}
              >
                {isSubmitting ? "Submitting..." : "Submit"}
              </Button>
            ) : (
              <h3>Submitted!</h3>
            )}

          </Stack>
        </DialogContent>
      </form>
    </Dialog>
  );
};

export default EditCatchModal;

