import React, { useState, useEffect } from 'react';
import { useParams, NavLink, Link } from 'react-router-dom';
import './EventNavigation.css';

function EventNavigation(props) {
  const { username, eventId } = useParams(); // Get username and eventId from the URL
  const currentUser = JSON.parse(window.localStorage.getItem('user'));
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 950);
  const [eventSettings, setEventSettings] = useState(null); // Store event settings

  useEffect(() => {
    // Get apiUrl based on environment
    const apiUrl = process.env.REACT_APP_NODE_ENV === 'production'
      ? process.env.REACT_APP_SERVER_URL_PRODUCTION
      : process.env.REACT_APP_SERVER_URL_STAGING;

    // Fetch event settings from the backend
    const fetchEventSettings = async () => {
      try {
        const response = await fetch(`${apiUrl}/api/load_event_profile`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ username, eventId }), // Send username and eventId in the request body
        });

        const data = await response.json();
        if (data.success) {
          setEventSettings(data.settings); // Store settings in state
          console.log('Event settings:', data.settings); // Print settings to console for debugging
        } else {
          console.error('Error fetching event settings:', data.message);
        }
      } catch (error) {
        console.error('Error fetching event settings:', error);
      }
    };

    fetchEventSettings();
  }, [username, eventId]);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 950);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const menu = document.getElementById('menu-toggle');
  
    function handleMenuClick(event) {
      if (menu && menu.checked === true) {  // Ensure menu exists
        if (document.body.scrollTop > 0 || document.documentElement.scrollTop > 0) {
          document.getElementById("menu").style.marginTop = "70px";
        } else {
          document.getElementById("menu").style.marginTop = "90px";
        }
      }
      if (menu && event.target instanceof HTMLAnchorElement) {  // Ensure menu exists
        menu.checked = false;
        document.getElementById("menu").style.marginTop = "0px";
      }
    }
  
    function scrollFunction() {
      const navbar = document.getElementById("navbar");
      if (!navbar) return; // Prevents errors if navbar doesn't exist yet

      if (document.body.scrollTop > 0 || document.documentElement.scrollTop > 0) {
        navbar.style.height = "70px";
        if (menu && menu.checked === true) {  
          document.getElementById("menu").style.marginTop = "70px";
        } else {
          document.getElementById("menu").style.marginTop = "0px";
        }
      } else {
        navbar.style.height = "90px";
        if (menu && menu.checked === true) {  
          document.getElementById("menu").style.marginTop = "90px";
        } else {
          document.getElementById("menu").style.marginTop = "0px";
        }
      }
    }
  
    function resizeFunction() {
      if (window.matchMedia("(max-width: 950px)").matches) {
        if ((document.body.scrollTop > 0 || document.documentElement.scrollTop > 0) && menu && menu.checked === true) {
          document.getElementById("menu").style.marginTop = "90px";
        } else if (!(document.body.scrollTop > 0 || document.documentElement.scrollTop > 0) && menu && menu.checked === false) {
          document.getElementById("menu").style.marginTop = "90px";
        }
      } else {
        if (menu) {
          document.getElementById("menu").style.marginTop = "0px";
          menu.checked = false;
        }
      }
    }
  
    document.addEventListener('click', handleMenuClick);
    window.onscroll = scrollFunction;
    window.onresize = resizeFunction;
  
    return () => {
      document.removeEventListener('click', handleMenuClick); // Cleanup event listener
      window.onscroll = null; // Cleanup scroll event listener
      window.onresize = null; // Cleanup resize event listener
    };
  }, []);  

  // HELPERS
  const getAbsoluteUrl = (url) => {
    if (url.startsWith('http://') || url.startsWith('https://')) {
      return url;
    } else {
      return 'http://' + url; // Use 'http://' as the default protocol
    }
  };
  
  // Prevent rendering until configs are loaded
  if (!eventSettings) {
    return <div>Loading...</div>;
  };

  return (
    <section id="navbar" className="top-nav" style={{ backgroundColor: "white"}}>

      {/* Event Title or Logo */}
      <div>
        <Link to={`/${username}/${eventId}/home`} className="logo">
          <img
            src={eventSettings.eventLogoUrl}
            alt={eventSettings.eventTitle}
            style={{
              maxHeight: '65px',
              maxWidth: '130px',
              objectFit: 'contain',
            }}
          />
        </Link>
      </div>
          
      <input id="menu-toggle" type="checkbox" />
      <label className='menu-button-container' htmlFor="menu-toggle">
        <div className='menu-button'></div>
      </label>
      
      <ul id="menu" className="menu">

        {/* HOME */}
        <li style={{ backgroundColor: "white", borderColor: "white", color: "black" }}>
          <NavLink to={`/${username}/${eventId}/home`} className={({ isActive }) => isActive ? "active" : undefined} style={{ color: "black" }}>Home</NavLink>
        </li>

        {/* INFO LINKS */}
        {!isMobile && (
          <div className="dropdown">
            <button className="dropbtn" style={{ color: "black" }}>Info <i className="fa fa-caret-down nav-icon"></i></button>
            <div className="dropdown-content">
              {/* Add Rules link if available */}
              {eventSettings.rulesLink && (
                <a href={getAbsoluteUrl(eventSettings.rulesLink)} target="_blank" style={{ backgroundColor: "white", color: "black" }}>Rules</a>
              )}
              {/* Add Tournament Site link if available */}
              {eventSettings.websiteLink && (
                <a href={getAbsoluteUrl(eventSettings.websiteLink)} target="_blank" style={{ backgroundColor: "white", color: "black" }}>Tournament Site</a>
              )}
            </div>
          </div>
        )}
        {isMobile && (
          <>
            {eventSettings.rulesLink && (
              <li style={{ backgroundColor: "white", borderColor: "white" }}>
                <a href={getAbsoluteUrl(eventSettings.rulesLink)} target="_blank" style={{ color: "black" }}>Rules</a>
              </li>
            )}
            {eventSettings.websiteLink && (
              <li style={{ backgroundColor: "white", borderColor: "white" }}>
                <a href={getAbsoluteUrl(eventSettings.websiteLink)} target="_blank" style={{ color: "black" }}>Tournament Site</a>
              </li>
            )}
          </>
        )}

        {/* TOURNAMENT LINKS */}
        {!isMobile && (
          <div className="dropdown">
            <button className="dropbtn" style={{ color: "black" }}>Tournament <i className="fa fa-caret-down nav-icon"></i></button>
            <div className="dropdown-content">
              <NavLink to={`/${username}/${eventId}/newsfeed`} className={({ isActive }) => isActive ? "active" : undefined} style={{ backgroundColor: "white", color: "black" }}>Newsfeed</NavLink>
              <NavLink to={`/${username}/${eventId}/leaderboard`} className={({ isActive }) => isActive ? "active" : undefined} style={{ backgroundColor: "white", color: "black" }}>Leaderboard</NavLink>
            </div>
          </div>
        )}
        {isMobile && (
          <>
            <li style={{ backgroundColor: "white", borderColor: "white" }}>
              <NavLink to={`/${username}/${eventId}/newsfeed`} className={({ isActive }) => isActive ? "active" : undefined} style={{ color: "black" }}>Newsfeed</NavLink>
            </li>
            <li style={{ backgroundColor: "white", borderColor: "white" }}>
              <NavLink to={`/${username}/${eventId}/leaderboard`} className={({ isActive }) => isActive ? "active" : undefined} style={{ color: "black" }}>Leaderboard</NavLink>
            </li>
          </>
        )}

        {/* ADMIN LINKS */}
        {!isMobile && (
          <div className="dropdown">
            <button className="dropbtn " style={{ color: "black" }}>Admin <i className="fa fa-caret-down nav-icon"></i></button>
            <div className="dropdown-content">
              <NavLink to={`/${username}/${eventId}/admin`} className={({ isActive }) => isActive ? "active" : undefined} style={{ backgroundColor: "white", color: "black" }}>Settings</NavLink>
              <NavLink to={`/${username}/dashboard`} className={({ isActive }) => isActive ? "active" : undefined} style={{ backgroundColor: "white", color: "black" }}>Dashboard</NavLink>
            </div>
          </div>
        )}
        {isMobile && (
          <>
            <li style={{ backgroundColor: "white", borderColor: "white" }}>
              <NavLink to={`/${username}/${eventId}/admin`} className={({ isActive }) => isActive ? "active" : undefined} style={{ color: "black" }}>Settings</NavLink>
            </li>
            <li style={{ backgroundColor: "white", borderColor: "white" }}>
              <NavLink to={`/${username}/dashboard`} className={({ isActive }) => isActive ? "active" : undefined} style={{ color: "black" }}>Dashboard</NavLink>
            </li>
          </>
        )}
      </ul>
    </section>
  );
}

export default EventNavigation;

