import React, { useState, useEffect } from 'react';
import { InputLabel, Button, Dialog, DialogContent, DialogTitle, IconButton, Stack } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import CloseIcon from "@mui/icons-material/Close";
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';

const DeleteTeamModal = (props) => {
  const [deleteInfo, setDeleteInfo] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  useEffect(() => {
    setDeleteInfo(props.deleteInfo);
  }, [props.deleteInfo]);

  const handleClose = () => {
    setIsSubmitting(false);
    setIsSubmitted(false);
    props.close();
  };

  const delayRefresh = () => {
    setTimeout(() => {
      window.location.reload();
    }, 2000);
  };

  const handleDelete = async () => {
    setIsSubmitting(true);

    try {
      let apiUrl = process.env.REACT_APP_NODE_ENV === "staging"
        ? process.env.REACT_APP_SERVER_URL_STAGING
        : process.env.REACT_APP_SERVER_URL_PRODUCTION;

      const response = await fetch(`${apiUrl}/api/admin_delete_event_teams`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          username: props.username,
          eventId: props.eventId,
          teamId: props.deleteInfo.teamId,
        })
      });

      if (response.ok) {
        toast.success('Team deleted successfully.');
        setIsSubmitted(true);
        delayRefresh();
      } else {
        toast.error('Error deleting team.');
        setIsSubmitting(false);
      }
    } catch (error) {
      console.error('Error deleting team:', error);
      toast.error('Error deleting team.');
      setIsSubmitting(false);
    }
  };

  return (
    <div>
      {deleteInfo &&
        <Dialog open={props.status} onClose={handleClose} fullWidth maxWidth="sm">
          <form action="/" method="POST" onSubmit={(e) => { e.preventDefault(); handleClose(); }}>
            <DialogTitle>
              Delete Team
              <IconButton onClick={handleClose} style={{ float: 'right' }}><CloseIcon color="primary"></CloseIcon></IconButton>
            </DialogTitle>
            <DialogContent>

              <Stack spacing={2} margin={2}>

                <div key="Team name">
                  <strong>Name:</strong> {deleteInfo["Team name"]}
                </div>

                <div key="teamId">
                  <strong>Team ID:</strong> {deleteInfo.teamId}
                </div>

                <div key="hasCheckedIn">
                  <strong>Has checked in?</strong> {deleteInfo.hasCheckedIn ? 'Yes' : 'No'}
                </div>

                {Object.entries(deleteInfo).map(([key, value]) => {
                  // return <div>{key}</div>
                  if ( !(key === "hasCheckedIn") && !(key === "teamId") && !(key === "Team name") ) {
                    return (<div key={key}>
                      <strong>{key}:</strong> {value}
                    </div>)
                  }
                })}
                
                <InputLabel style={{ fontWeight: 'bold', color: 'red', wordWrap: 'break-word' }}>Are you sure?</InputLabel>

                {!isSubmitted ? (
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={handleDelete}
                    disabled={isSubmitting}
                    startIcon={isSubmitting ? <CircularProgress size={20} /> : null}
                  >
                    {isSubmitting ? "Deleting..." : "Yes, Delete This Team"}
                  </Button>
                ) : (
                  <h3>Submitted!</h3>
                )}
              </Stack>
            </DialogContent>
          </form>
        </Dialog>
      }
    </div>
  );
};

export default DeleteTeamModal;

