import React, { useEffect, useState } from 'react';
import { Box, useMediaQuery } from '@mui/material';
import { toast } from 'react-toastify';
import AnimatedPage from './AnimatedPage';
import EventCard from '../components/dashboard/EventCard';
import CreateEventCard from '../components/dashboard/CreateEventCard';
import PlaceholderCard from '../components/dashboard/PlaceholderCard';
import CreateEventModal from '../components/modals/CreateEventModal'; 
import './DashboardPage.css';

const DashboardPage = () => {
  console.log('In DashboardPage...')
  const currentUser = JSON.parse(window.localStorage.getItem('user'));
  const username = window.localStorage.getItem('username');
  const [activeEvents, setActiveEvents] = useState([]);
  const [pastEvents, setPastEvents] = useState([]);
  const [isCreateEventModalOpen, setIsCreateEventModalOpen] = useState();
  const isMobile = useMediaQuery('(max-width: 600px)'); // Detect screen size
  const cardWidth = isMobile ? '100%' : '30%';
  const cardHeight = '350px';

  useEffect(() => {
    // Define apiUrl based on environment
    const apiUrl = process.env.REACT_APP_NODE_ENV === 'production'
      ? process.env.REACT_APP_SERVER_URL_PRODUCTION
      : process.env.REACT_APP_SERVER_URL_STAGING;

    if (!currentUser || !apiUrl) return; // Ensure currentUser and apiUrl are defined

    const fetchUserData = async () => {
      try {
        // Get user profile data using POST and sending the userId in the request body
        const profileResponse = await fetch(`${apiUrl}/api/get_user_profile_data`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ userId: currentUser.uid }),
        });
        const profileData = await profileResponse.json();
        if (!profileData.success) {
          toast.error('Failed to fetch user profile data');
          return;
        }
        console.log('profileData', profileData);

        // Fetch event settings data using the profile's username in a POST request
        const settingsResponse = await fetch(`${apiUrl}/api/get_event_settings_data`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ username: profileData.data.username }),
        });
        const settingsData = await settingsResponse.json();
        if (!settingsData.success) {
          toast.error('Failed to fetch event settings data');
          return;
        }
        console.log('settingsData', settingsData);

        // Separate events into active and past categories based on the settings data
        const now = Date.now(); // Get current time in epoch milliseconds
        const active = [];
        const past = [];

        settingsData.data.forEach((eventObj) => {
          const { eventId, settings } = eventObj; // Destructure eventId and settings
          settings["eventId"] = eventId; // Include eventId in settings object

          // Convert settings["eventEndDateTimeGMT"] to number and sort into active/past
          const eventEndDateEpoch = Number(settings["eventEndDateTimeGMT"]);

          if (eventEndDateEpoch > now) {
            active.push(settings);
          } else {
            past.push(settings);
          }
        });

        setActiveEvents(active);
        setPastEvents(past);
      } catch (error) {
        toast.error('An error occurred while fetching data');
        console.log(error);
      }
    };

    fetchUserData();
    setIsCreateEventModalOpen(false);
  }, []); // Re-run the effect when currentUser changes

  // HELPERS
  const handleOpenCreateEventModal = () => {
    setIsCreateEventModalOpen(true);
  };
  const handleCloseCreateEventModal = () => {
    setIsCreateEventModalOpen(false);
  };

  const addPlaceholderCards = (eventList) => {
    const remainder = eventList.length % 3;
    const placeholderCount = remainder === 0 ? 0 : 3 - remainder;
    return Array(placeholderCount).fill(<PlaceholderCard cardWidth={cardWidth} cardHeight={cardHeight} />);
  };

  return (
    <AnimatedPage>
      <section className="dashboard-content">
        <div className="dashboard-main-content">
          <Box sx={{ width: '90%', typography: 'body1' }}>
            <h2 style={{ textAlign: 'center' }}>Active Events</h2>
            <Box display="flex" flexWrap="wrap" justifyContent="space-between">
              {/* Render Active Events */}
              {activeEvents.map((event, index) => (
                <EventCard key={event.eventId} event={event} cardWidth={cardWidth} cardHeight={cardHeight} username={username}/>
              ))}
              {/* Add Create Event Card */}
              <CreateEventCard cardWidth={cardWidth} cardHeight={cardHeight} open={handleOpenCreateEventModal} />
              {/* Add Placeholder Cards if necessary */}
              {!isMobile && addPlaceholderCards([...activeEvents, {}])}
            </Box>
            <br />

            {/* Only show Past Tournaments section if there are past events */}
            {pastEvents.length > 0 && (
              <>
                <h2 style={{ textAlign: 'center' }}>Past Events</h2>
                <Box display="flex" flexWrap="wrap" justifyContent="space-between">
                  {/* Render Past Events */}
                  {pastEvents.map((event, index) => (
                    <EventCard key={event.eventId} event={event} cardWidth={cardWidth} cardHeight={cardHeight} />
                  ))}
                  {/* Add Placeholder Cards if necessary */}
                  {!isMobile && addPlaceholderCards(pastEvents)}
                </Box>
              </>
            )}
          </Box>
        </div>

        {/* Create Event Modal */}
        <CreateEventModal status={isCreateEventModalOpen} open={handleOpenCreateEventModal} close={handleCloseCreateEventModal} />

      </section>
    </AnimatedPage>
  );
};

export default DashboardPage;

