import React, { useState } from 'react';
import { Grid, Paper, Avatar, TextField, Button, CircularProgress } from '@mui/material';
import { LockOutlined } from '@mui/icons-material';

const EventAuthenticate = (props) => {
  const paperStyle = { padding: 20, height: 400, width: 350, margin: 20 };
  const avatarStyle = { backgroundColor: '#1876D1' };

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false); // Submission state
  const [isSubmitted, setIsSubmitted] = useState(false);   // Submission complete state

  const handleLogin = async (e) => {
    e.preventDefault(); // Prevent default form submission behavior

    setIsSubmitting(true); // Disable button and show spinner

    // Assuming `props.handleLogin` is an async function that handles authentication
    try {
      await props.handleLogin(e, email, password);
      setIsSubmitted(true); // Mark as submitted
    } catch (error) {
      console.error("Login failed:", error);
      setIsSubmitting(false); // Re-enable button if login fails
    }
  }

  return (
    <Grid>
      <Paper variant='elevation' elevation={10} style={paperStyle}>
        <Grid align='center'>
          <Avatar style={avatarStyle}>
            <LockOutlined />
          </Avatar>
        </Grid>
        <br />
        <h2>Administrator Login</h2>
        <br />
        <TextField
          variant="outlined"
          label='Email'
          placeholder="Enter admin email"
          fullWidth
          required
          onChange={(e) => setEmail(e.target.value)}
          disabled={isSubmitting || isSubmitted} // Disable when submitting or after submission
        />
        <br /><br />
        <TextField
          variant="outlined"
          label='Password'
          placeholder="Enter admin password"
          fullWidth
          required
          type='password'
          onChange={(e) => setPassword(e.target.value)}
          disabled={isSubmitting || isSubmitted} // Disable when submitting or after submission
        />
        <br /><br /><br />
        
        {/* Button logic */}
        {!isSubmitted ? (
          <Button
            type="submit"
            color="primary"
            variant="contained"
            fullWidth
            onClick={handleLogin}
            disabled={isSubmitting} // Disable button during submission
          >
            {isSubmitting ? <CircularProgress size={24} /> : "Sign In"}
          </Button>
        ) : (
          <Button
            type="submit"
            color="primary"
            variant="contained"
            fullWidth
            disabled
          >
            Loading...
          </Button>
        )}
      </Paper>
    </Grid>
  )
}

export default EventAuthenticate;

