// leaderboardReports.js

import jsPDF from 'jspdf';
import 'jspdf-autotable';

// Import Day.js and plugins
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat'; // For advanced date formats
import utc from 'dayjs/plugin/utc'; // To handle UTC dates
import timezone from 'dayjs/plugin/timezone'; // To handle time zones

// Import your desktop columns configurations
import {
  catchAndReleaseDesktopColumns,
  weightOnlyDesktopColumns,
  weightAndLengthDesktopColumns,
  weightAndLengthAndGirthDesktopColumns,
  lengthOnlyDesktopColumns,
  lengthAndWeightDesktopColumns,
  lengthAndGirthDesktopColumns,
} from '../config/config'; // Adjust the path as needed based on your folder structure

dayjs.extend(advancedFormat);
dayjs.extend(utc);
dayjs.extend(timezone);

const addPageNumbers = (doc) => {
  const pageCount = doc.internal.getNumberOfPages();
  for (let i = 1; i <= pageCount; i++) {
    doc.setPage(i);
    doc.setFontSize(10);
    doc.text(
      `${i} of ${pageCount}`,
      doc.internal.pageSize.getWidth() - 25,
      doc.internal.pageSize.getHeight() - 10
    );
  }
};

export const generateLeaderboardReport = async (
  username,
  eventId,
  tournamentName
) => {
  const doc = new jsPDF('landscape');
  const currentDate = dayjs().tz('America/Chicago').format('MMMM D, YYYY h:mm A');
  const currentYear = new Date().getFullYear();

  const apiUrl =
    process.env.REACT_APP_NODE_ENV === 'production'
      ? process.env.REACT_APP_SERVER_URL_PRODUCTION
      : process.env.REACT_APP_SERVER_URL_STAGING;

  // Fetch event settings
  let eventSettings;
  try {
    const response = await fetch(`${apiUrl}/api/admin_get_event_settings`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ username, eventId }),
    });
    const data = await response.json();
    if (response.ok && data.success) {
      eventSettings = data.settings;
    } else {
      throw new Error('Error fetching event settings: ' + data.message);
    }
  } catch (error) {
    console.error('Error fetching event settings:', error);
    return;
  }

  // Build queries
  const queries = eventSettings.catchRows.map((element) => {
    // Assign URL and columns
    let url;
    let desktopColumns;
    if (element.scoring === 'Catch & Release (Tiebreaker: Earliest Time)') {
      url = 'get_event_leaderboard_for_catch_and_release_species';
      desktopColumns = catchAndReleaseDesktopColumns;
    } else if (element.scoring === 'Weight Only (Tiebreaker: None)') {
      url = 'get_event_leaderboard_for_weight_only_species';
      desktopColumns = weightOnlyDesktopColumns;
    } else if (element.scoring === 'Weight (Tiebreaker: Length)') {
      url = 'get_event_leaderboard_for_weight_and_length_species';
      desktopColumns = weightAndLengthDesktopColumns;
    } else if (element.scoring === 'Weight (Tiebreaker: Length, Girth)') {
      url = 'get_event_leaderboard_for_weight_and_length_and_girth_species';
      desktopColumns = weightAndLengthAndGirthDesktopColumns;
    } else if (element.scoring === 'Length Only (Tiebreaker: None)') {
      url = 'get_event_leaderboard_for_length_only_species';
      desktopColumns = lengthOnlyDesktopColumns;
    } else if (element.scoring === 'Length (Tiebreaker: Weight)') {
      url = 'get_event_leaderboard_for_length_and_weight_species';
      desktopColumns = lengthAndWeightDesktopColumns;
    } else if (element.scoring === 'Length (Tiebreaker: Girth)') {
      url = 'get_event_leaderboard_for_length_and_girth_species';
      desktopColumns = lengthAndGirthDesktopColumns;
    }

    return {
      title: element.species,
      subtitle: '',
      species: element.species,
      url: url,
      desktopColumns: desktopColumns,
      numPlaces: eventSettings.numPlaces || 3,
    };
  });

  // Fetch data for each query
  try {
    const res = await Promise.all(
      queries.map((query) =>
        fetch(`${apiUrl}/api/${query.url}`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            username: username,
            eventId: eventId,
            species: query.species,
            isReport: true, // Fetch all rows for the report
            numPlaces: query.numPlaces,
          }),
        })
          .then((res) => res.json())
          .then((result) => {
            let tempRows = [];
            if (Array.isArray(result)) {
              tempRows = result.map((item, i) => ({ ...item, id: i }));
            } else {
              Object.keys(result).forEach((key, i) => {
                let tempObject = { ...result[key], id: i };
                tempRows.push(tempObject);
              });
            }
            return {
              title: query.title,
              subtitle: query.subtitle,
              rows: tempRows,
              desktopColumns: query.desktopColumns,
            };
          })
      )
    );

    // Generate the PDF
    res.forEach((category, index) => {
      if (index > 0) doc.addPage();

      doc.setFontSize(16);
      doc.setFont('helvetica', 'bold');
      doc.text(
        `Leaderboard for ${category.title} - ${tournamentName} ${currentYear}`,
        10,
        10
      );
      doc.text(`Report generated on ${currentDate}`, 10, 18);

      // Generate table columns based on category.desktopColumns
      const tableColumns = category.desktopColumns.map((col) => col.headerName);

      // Define possible date field names, include the correct field name
      const dateFields = new Set(['dateTime', 'dateTimeGMT', 'catchDateTime', 'lastCatchTime', 'lastCatch']);

      const tableRows = category.rows.map((row) =>
        category.desktopColumns.map((col) => {
          let value = row[col.field];

          if (dateFields.has(col.field)) {
            // Log for debugging
            console.log(`Formatting date for field: ${col.field}, Original Value: ${value}`);
            
            if (value && dayjs(value).isValid()) {
              value = dayjs.utc(value).tz('America/Chicago').format('MMMM D, YYYY h:mm A');
              console.log(`Formatted Value: ${value}`);
            } else {
              value = 'Invalid Date';
            }
          } else {
            value = value !== undefined && value !== null ? value : 'N/A';
          }
          return value;
        })
      );

      doc.autoTable({
        startY: 30,
        head: [tableColumns],
        body: tableRows,
        theme: 'striped',
        styles: {
          fontSize: 10,
          halign: 'center',
          valign: 'middle',
          overflow: 'linebreak',
        },
        headStyles: {
          fillColor: '#02133E',
          textColor: '#ffffff',
          halign: 'center',
        },
      });
    });

    // Add page numbers
    addPageNumbers(doc);

    doc.save(`${tournamentName} ${currentYear} Leaderboard Report.pdf`);
  } catch (error) {
    console.error('Error generating leaderboard report:', error);
  }
};

