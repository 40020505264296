import React, { useState, useEffect } from 'react';
import { InputLabel, Divider, Button, Dialog, DialogContent, DialogTitle, IconButton, Stack, TextField, Autocomplete, Grid } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import CloseIcon from "@mui/icons-material/Close";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useMediaQuery from '@mui/material/useMediaQuery'; // Import the useMediaQuery hook

const AddCatchModal = (props) => {
  const [isSubmitting, setIsSubmitting] = useState(false); 
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [formData, setFormData] = useState({ "Team name": "" });
  const [teamNames, setTeamNames] = useState([]); 
  const [isLoadingTeamNames, setIsLoadingTeamNames] = useState(true);
  const [numCatches, setNumCatches] = useState(0);
  const [numNewCatches, setNumNewCatches] = useState(0);
  const [catchData, setCatchData] = useState([]);
  const [speciesList, setSpeciesList] = useState([]);
  const maxCatches = props.eventSettings.tier === "Tier 1" ? 250 : Infinity;
  const isMobile = useMediaQuery('(max-width:650px)'); // Define the media query

  useEffect(() => {
    fetchTeamNames();
    fetchCatchCount();
    loadSpeciesOptions();
  }, []); 

  const fetchTeamNames = async () => {
    try {
      const apiUrl = process.env.REACT_APP_NODE_ENV === 'production'
        ? process.env.REACT_APP_SERVER_URL_PRODUCTION
        : process.env.REACT_APP_SERVER_URL_STAGING;

      const response = await fetch(`${apiUrl}/api/admin_get_event_team_names`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username: props.username, eventId: props.eventId })
      });

      if (response.ok) {
        const data = await response.json();
        setTeamNames(data.teamNames || []);
      } else {
        toast.error('Error fetching team names.');
      }
    } catch (error) {
      console.error('Error fetching team names:', error);
      toast.error('Error fetching team names.');
    } finally {
      setIsLoadingTeamNames(false); 
    }
  };

  const fetchCatchCount = async () => {
    try {
      const apiUrl = process.env.REACT_APP_NODE_ENV === 'production'
        ? process.env.REACT_APP_SERVER_URL_PRODUCTION
        : process.env.REACT_APP_SERVER_URL_STAGING;
  
      const response = await fetch(`${apiUrl}/api/admin_get_number_of_event_catches`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username: props.username, eventId: props.eventId })
      });
  
      if (response.ok) {
        const data = await response.json();
  
        const totalCatchesFromDb = Object.values(data).reduce((sum, count) => sum + count, 0);
        setNumCatches(totalCatchesFromDb);

      } else {
        toast.error('Error fetching catch count.');
      }
    } catch (error) {
      console.error('Error fetching catch count:', error);
      toast.error('Error fetching catch count.');
    }
  };

  const loadSpeciesOptions = () => {
    const speciesOptions = props.eventSettings.catchRows.map(row => ({
      label: row.species,
      category: row.scoring,
      points: row.points,
    }));
    setSpeciesList(speciesOptions);
  };

  const handleClose = () => {
    setFormData({ "Team name": "" });
    setNumCatches(0);
    setNumNewCatches(0);
    setCatchData([]);
    setIsSubmitting(false);
    setIsSubmitted(false);
    props.close();
  };

  const validateUserInput = () => {
    let inputIsValid = true;
  
    if (!formData['Team name']) {
      toast.error('Please select a team.');
      inputIsValid = false;
    }
  
    let counter = 1;
    catchData.forEach((entry) => {
      if (!entry.species) {
        toast.error(`Species is required for Catch #${counter}`);
        inputIsValid = false;
      }
  
      if (entry.dateTimeIsRequired && !entry.dateTime) {
        toast.error(`Catch date/time is required for Catch #${counter}`);
        inputIsValid = false;
      }
  
      if (entry.weightIsRequired && entry.weight <= 0) {
        toast.error(`Weight must be greater than zero for Catch #${counter}`);
        inputIsValid = false;
      }
  
      if (entry.lengthIsRequired && entry.length <= 0) {
        toast.error(`Length must be greater than zero for Catch #${counter}`);
        inputIsValid = false;
      }
  
      if (entry.girthIsRequired && entry.girth <= 0) {
        toast.error(`Girth must be greater than zero for Catch #${counter}`);
        inputIsValid = false;
      }
  
      counter = counter + 1;
    });
  
    // FIXME: Check if the total number of catches (existing + new) exceeds the max allowed catch count
    const totalNewCatches = catchData.length;
    const totalCatches = numNewCatches + totalNewCatches;
    if (totalCatches > maxCatches) {
      toast.error(`Adding these catches would exceed the maximum allowed catch count of ${maxCatches}.`);
      inputIsValid = false;
    }
  
    return inputIsValid;
  };

  const handleChangeNumberOfCatches = (e) => {
    setNumNewCatches(e.target.value);
    if (e.target.value > 0) {
      const catchDataList = Array.from({ length: e.target.value }, (_, i) => ({
        id: i,
        teamName: formData['Team name'],
        species: "",
        dateTime: undefined,
        length: 0,
        girth: 0,
        weight: 0,
        points: 0,
        speciesType: "",
        catchPhoto: null,
        dateTimeIsRequired: false,
        weightIsRequired: false,
        lengthIsRequired: false,
        girthIsRequired: false,
      }));
      setCatchData(catchDataList);
    } else {
      setCatchData([]);
    }
  };

  const handleTeamSelection = (event, value) => {
    setFormData(prevData => ({
      ...prevData,
      'Team name': value
    }));

    setCatchData(prevData =>
      prevData.map(catchEntry => ({
        ...catchEntry,
        teamName: value
      }))
    );
  };

  const handleSpeciesSelection = (index, event, value) => {
    const newCatchData = [...catchData];
    newCatchData[index].species = value.label;
    newCatchData[index].points = value.points;
    newCatchData[index].speciesType = value.category;
  
    // Update required fields based on species type (scoring)
    switch (value.category) {
      case 'Catch & Release (Tiebreaker: Earliest Time)':
        newCatchData[index].dateTimeIsRequired = true;
        newCatchData[index].weightIsRequired = false;
        newCatchData[index].lengthIsRequired = false;
        newCatchData[index].girthIsRequired = false;
        break;
      case 'Weight Only (Tiebreaker: None)':
        newCatchData[index].dateTimeIsRequired = false;
        newCatchData[index].weightIsRequired = true;
        newCatchData[index].lengthIsRequired = false;
        newCatchData[index].girthIsRequired = false;
        newCatchData[index].dateTime = dayjs().toISOString(); // Set current date/time for non-required fields
        break;
      case 'Weight (Tiebreaker: Length)':
        newCatchData[index].dateTimeIsRequired = false;
        newCatchData[index].weightIsRequired = true;
        newCatchData[index].lengthIsRequired = true;
        newCatchData[index].girthIsRequired = false;
        newCatchData[index].dateTime = dayjs().toISOString(); // Set current date/time for non-required fields
        break;
      case 'Weight (Tiebreaker: Length, Girth)':
        newCatchData[index].dateTimeIsRequired = false;
        newCatchData[index].weightIsRequired = true;
        newCatchData[index].lengthIsRequired = true;
        newCatchData[index].girthIsRequired = true;
        newCatchData[index].dateTime = dayjs().toISOString(); // Set current date/time for non-required fields
        break;
      case 'Length Only (Tiebreaker: None)':
        newCatchData[index].dateTimeIsRequired = false;
        newCatchData[index].weightIsRequired = false;
        newCatchData[index].lengthIsRequired = true;
        newCatchData[index].girthIsRequired = false;
        newCatchData[index].dateTime = dayjs().toISOString(); // Set current date/time for non-required fields
        break;
      case 'Length (Tiebreaker: Weight)':
        newCatchData[index].dateTimeIsRequired = false;
        newCatchData[index].weightIsRequired = true;
        newCatchData[index].lengthIsRequired = true;
        newCatchData[index].girthIsRequired = false;
        newCatchData[index].dateTime = dayjs().toISOString(); // Set current date/time for non-required fields
        break;
      case 'Length (Tiebreaker: Girth)':
        newCatchData[index].dateTimeIsRequired = false;
        newCatchData[index].weightIsRequired = false;
        newCatchData[index].lengthIsRequired = true;
        newCatchData[index].girthIsRequired = true;
        newCatchData[index].dateTime = dayjs().toISOString(); // Set current date/time for non-required fields
        break;
      default:
        newCatchData[index].dateTime = dayjs().toISOString(); // Set current date/time for any other case
        break;
    }
  
    setCatchData(newCatchData);
  };
  
  const handleDateTimeSelection = (index, event) => {
    const newCatchData = [...catchData];
    newCatchData[index].dateTime = event ? event.toISOString() : null;
    setCatchData(newCatchData);
  };

  const calculatePoints = (catchEntry) => {
    if (catchEntry.speciesType === 'Catch & Release (Tiebreaker: Earliest Time)') {
      return catchEntry.points; // Use points directly for Catch & Release
    } else if (catchEntry.speciesType.includes('Weight')) {
      return Math.floor(catchEntry.weight || 0); // Use weight for points, rounded down
    } else if (catchEntry.speciesType.includes('Length')) {
      return Math.floor(catchEntry.length || 0); // Use length for points, rounded down
    }
    return 0;
  };
  
  const handleWeightSelection = (index, event) => {
    const newCatchData = [...catchData];
    newCatchData[index].weight = event.target.value;
  
    // Recalculate points based on the new weight
    newCatchData[index].points = calculatePoints(newCatchData[index]);
  
    setCatchData(newCatchData);
  };
  
  const handleLengthSelection = (index, event) => {
    const newCatchData = [...catchData];
    newCatchData[index].length = event.target.value;
  
    // Recalculate points based on the new length
    newCatchData[index].points = calculatePoints(newCatchData[index]);
  
    setCatchData(newCatchData);
  };
  
  const handleGirthSelection = (index, event) => {
    const newCatchData = [...catchData];
    newCatchData[index].girth = event.target.value;
  
    // Recalculate points if necessary, for example, if girth affects points in some species
    newCatchData[index].points = calculatePoints(newCatchData[index]);
  
    setCatchData(newCatchData);
  };  

  const handleCreateCatches = async () => {
    if (validateUserInput()) {
      setIsSubmitting(true);
  
      const catches = catchData.map((item, index) => ({
        teamName: item.teamName,
        species: item.species,
        speciesType: item.speciesType,
        dateTime: item.dateTime || '',
        length: item.length,
        girth: item.girth,
        weight: item.weight,
        points: item.points,
        catchPhoto: item.catchPhoto, // Include this only if catchPhoto is needed and in JSON-compatible format (e.g., base64)
      }));
  
      const requestBody = {
        username: props.username,
        eventId: props.eventId,
        catches
      };
  
      let apiUrl = process.env.REACT_APP_NODE_ENV === 'staging'
        ? process.env.REACT_APP_SERVER_URL_STAGING
        : process.env.REACT_APP_SERVER_URL_PRODUCTION;
  
      fetch(`${apiUrl}/api/admin_create_event_catches`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(requestBody), // Send JSON data
      })
      .then((res) => {
        if (res.ok) {
          toast.success(`Successfully added ${numNewCatches} catches!`);
          setIsSubmitted(true);
          delayRefresh();
        } else {
          toast.error('Error while saving catches.');
          setIsSubmitting(false);
        }
      })
      .catch((e) => {
        console.error(e);
        toast.error('Error saving catches to the database.');
        setIsSubmitting(false);
      });
    }
  };
  
  const delayRefresh = () => {
    setTimeout(() => {
      window.location.reload();
    }, 2000);
  };

  if (isLoadingTeamNames) {
    return (
      <Dialog open={props.status} onClose={handleClose} fullWidth maxWidth="xl">
        <DialogContent>
          <CircularProgress size={24} />
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Dialog open={props.status} onClose={handleClose} fullWidth maxWidth="xl">
      <form onSubmit={(e) => e.preventDefault()}>
        <DialogTitle>
          Add {props.year} Catches
          <IconButton onClick={handleClose} style={{float:'right'}}>
            <CloseIcon color="primary" />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Stack spacing={2} margin={2}>
            <Autocomplete
              id="team-autocomplete"
              options={teamNames}
              value={formData['Team name']}
              onChange={handleTeamSelection}
              renderInput={(params) => (
                <TextField {...params} label="Select team" required />
              )}
            />
            <TextField
              label="Number of catches"
              type="number"
              value={numNewCatches}
              onChange={handleChangeNumberOfCatches}
              fullWidth
              required
              inputProps={{ min: 0 }}
            />
            {catchData.length > 0 && catchData.map((_, index) => (
              <div key={index}>

                <Divider />
                <br/>
                <InputLabel>Catch #{index + 1} ({calculatePoints(catchData[index])} points)</InputLabel>
                <br/>
                <Autocomplete
                  disablePortal
                  options={speciesList}
                  groupBy={(option) => option.category}
                  onChange={(event, value) => handleSpeciesSelection(index, event, value)}
                  renderInput={(params) => (
                    <TextField {...params} label="Select species" required />
                  )}
                />

                {catchData[index].dateTimeIsRequired && (
                  <div>
                    <br/>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DateTimePicker
                        label="Catch Date/Time"
                        value={catchData[index].dateTime ? dayjs(catchData[index].dateTime) : null}
                        onChange={(event) => handleDateTimeSelection(index, event)}
                        renderInput={(params) => <TextField {...params} fullWidth required />}
                        minDate={dayjs(Number(props.eventSettings.eventStartDateTimeGMT))}
                        maxDate={dayjs(Number(props.eventSettings.eventEndDateTimeGMT))}
                      />
                    </LocalizationProvider>
                  </div>
                )}

                {/*  Desktop or tablet view */}
                { !isMobile && (

                  <div>
                    <br/>

                    {catchData[index].weightIsRequired && (
                      <TextField
                        label="Weight (by 1/10 lb)"
                        type="number"
                        value={catchData[index].weight || ''}
                        onChange={(e) => handleWeightSelection(index, e)}
                        InputProps={{ inputProps: { step: 0.1, min: 0.1 } }}
                      />
                    )}

                    <span> </span>

                    {catchData[index].lengthIsRequired && (
                      <TextField
                        label="Length (by 1/8 inch)"
                        type="number"
                        value={catchData[index].length || ''}
                        onChange={(e) => handleLengthSelection(index, e)}
                        InputProps={{ inputProps: { step: 0.125, min: 0.125 } }}
                      />
                    )}

                    <span> </span>

                    {catchData[index].girthIsRequired && (
                      <TextField
                        label="Girth (by 1/8 inch)"
                        type="number"
                        value={catchData[index].girth || ''}
                        onChange={(e) => handleGirthSelection(index, e)}
                        InputProps={{ inputProps: { step: 0.125, min: 0.125 } }}
                      />
                    )}

                  </div>
                )}

                {/* Mobile view */}
                { isMobile && 
                  <div>
                    <div>
                  <br/>
                  {catchData[index].weightIsRequired && (
                    <TextField
                      label="Weight (by 1/10 lb)"
                      type="number"
                      value={catchData[index].weight || ''}
                      onChange={(e) => handleWeightSelection(index, e)}
                      InputProps={{ inputProps: { step: 0.1, min: 0.1 } }}
                    />
                  )}
                </div>

                <div>
                  <br/>
                  {catchData[index].lengthIsRequired && (
                    <TextField
                      label="Length (by 1/8 inch)"
                      type="number"
                      value={catchData[index].length || ''}
                      onChange={(e) => handleLengthSelection(index, e)}
                      InputProps={{ inputProps: { step: 0.125, min: 0.125 } }}
                    />
                  )}
                </div>

                <div>
                  <br/>
                  {catchData[index].girthIsRequired && (
                    <TextField
                      label="Girth (by 1/8 inch)"
                      type="number"
                      value={catchData[index].girth || ''}
                      onChange={(e) => handleGirthSelection(index, e)}
                      InputProps={{ inputProps: { step: 0.125, min: 0.125 } }}
                    />
                  )}
                </div>

                  </div>
                }
                

              </div>

            ))}
            <Button
              disabled={isSubmitting || numNewCatches <= 0}
              color="primary"
              variant="contained"
              onClick={handleCreateCatches}
              startIcon={isSubmitting ? <CircularProgress size={20} /> : null}
            >
              {isSubmitting ? "Submitting..." : "Submit"}
            </Button>
            {isSubmitted && <h3>Submitted!</h3>}
          </Stack>
        </DialogContent>
      </form>
    </Dialog>
  );
};

export default AddCatchModal;

