import React, { useEffect, useState } from 'react';
import { Button, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom'; // Import useNavigate hook
import '../../pages/DashboardPage.css';
import defaultNoEventImage from '../../images/defaultNoEventImage.png';

function EventCard({ event, cardWidth, cardHeight }) {
  const [logoImage, setLogoImage] = useState(defaultNoEventImage);
  const isMobile = useMediaQuery('(max-width: 600px)');
  const navigate = useNavigate(); // Initialize the useNavigate hook

  const username = window.localStorage.getItem('username'); // Retrieve the username from localStorage

  useEffect(() => {
    if (event.eventLogoUrl) {
      setLogoImage(event.eventLogoUrl);
    }
  }, [event.eventLogoUrl]);

  // Format epoch time into "MMM d yyyy" format
  const formatDate = (epochTime) => {
    const options = { month: 'short', day: 'numeric' };
    const date = new Date(Number(epochTime));
    return `${date.toLocaleDateString('en-US', options)} ${date.getFullYear()}`;
  };

  // Navigate to the event home page on card click
  const handleEventClick = () => {
    if (username && event.eventId) {
      navigate(`/${username}/${event.eventId}/home`); // Navigate to the event home page
    }
  };

  return (
    <Button
      className='dashboard-card-container'
      style={{
        width: cardWidth,
        height: cardHeight,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        border: '2px solid #cccccc',
        padding: '16px',
        cursor: 'pointer',
        textTransform: 'none',
        transition: 'background-color 0.3s, box-shadow 0.3s',
        marginBottom: isMobile ? '16px' : '0px',
      }}
      onClick={handleEventClick} // Call the function to navigate
      onMouseOver={(e) => {
        e.currentTarget.style.backgroundColor = "#f0f0f0";
        e.currentTarget.style.boxShadow = "0px 4px 8px rgba(0, 0, 0, 0.2)";
      }}
      onMouseOut={(e) => {
        e.currentTarget.style.backgroundColor = "#fff";
        e.currentTarget.style.boxShadow = "none";
      }}
    >
      <img
        src={logoImage}
        alt={event.eventTitle}
        style={{
          width: '70%',
          height: '140px',
          objectFit: 'contain',
          marginBottom: '16px',
        }}
      />
      <h3 style={{ margin: '8px 0', color: '#333', fontSize: '18px' }}>{event.eventTitle}</h3>
      <h4 style={{ margin: '4px 0', color: '#666' }}>
        {formatDate(event.eventStartDateTimeGMT)} - {formatDate(event.eventEndDateTimeGMT)}
      </h4>
    </Button>
  );
}

export default EventCard;
