import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import dayjs from 'dayjs'; // Ensure you have dayjs installed for date formatting
import './LeaderboardResultTable.css';

function LeaderboardResultTable(props) {

  // Modify columns to apply the valueFormatter dynamically
  const formattedColumns = props.columns.map((col) => {
    if (col.isDateTime) {
      return {
        ...col,
        valueFormatter: (params) => dayjs(params).format('MMMM Do, YYYY @ hh:mm A'),
      };
    }
    return col;
  });

  return (
    <div style={{ ...props.style, overflowX: 'auto' }}>
      <h1 style={{ fontSize: '30px', color: "black", marginBottom: '20px' }}>
        {props.title} 
        {props.subtitle && (
          <span style={{ fontSize: '30px', fontStyle: "italic", fontWeight: 'lighter', marginLeft: '10px', color: "black"}}>
            ({props.subtitle})
          </span>
        )}
      </h1>
      <DataGrid
        rows={props.rows || []}
        columns={formattedColumns}
        columnVisibilityModel={props.visibility}
        sx={{
          overflowX: 'auto',
          '.MuiDataGrid-row.Mui-odd': {
            backgroundColor: 'rgba(234, 234, 234)',
            fontSize: '16px',
          },
          '.MuiDataGrid-columnHeaderTitleContainer': {
            backgroundColor: props.eventSettings.backgroundColor,
            color: props.eventSettings.headlineColor,
            fontSize: '16px',
            ".MuiSvgIcon-root": {
              color: props.eventSettings.headlineColor,
            }
          },
          '& .super-app-theme--header': {
            backgroundColor: props.eventSettings.backgroundColor,
            color: props.eventSettings.headlineColor,
            fontSize: '16px',
          },
          '& .MuiDataGrid-cell': {
            fontSize: '16px',
            color: "black",
          },
        }}
        hideFooter={true}
        density='compact'
        getRowClassName={(params) =>
          params.indexRelativeToCurrentPage % 2 === 0 ? 'Mui-even' : 'Mui-odd'
        }
        disableColumnMenu
        disableColumnFilter
        disableColumnSelector
        disableColumnSorting
      />
      <br />
      <br />
    </div>
  );
}

export default LeaderboardResultTable;

